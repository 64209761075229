import React from 'react';
import PropTypes from 'prop-types';
import { useWindowWidth } from '../../../utils/hooks';

import If from 'components/If/If';
import Spinner from '../../Spinner/Spinner';

import {
	StyledModal,
	Content,
	Title,
	Header,
	Close,
	Footer,
	Button,
  LeftChevron
} from './Modal.styled';
const Modal = ({
	title,
	isOpen,
	onReject,
	onConfirm = false,
	isLoading,
	rejectText,
	confirmText = 'yes',
	canConfirm = true,
	children,
	buttonsPosition,
	filterModalMobile,
	maxWidth,
  scrollable = false,
  ButtonComponents = null,
}) => {
	const haveFooter = (onReject && rejectText) || onConfirm;
	const windowWidth = useWindowWidth();
	const headerIcon =
		windowWidth < 768 && filterModalMobile ? (
			<LeftChevron onClick={onReject} />
		) : (
			<Close onClick={onReject} />
		);
	return (
		<StyledModal
			isOpen={isOpen}
			onBackgroundClick={onReject}
			onEscapeKeydown={onReject}
			filterModalMobile={filterModalMobile}
			maxWidth={maxWidth}
			scrollable={scrollable}
		>
			{isLoading && <Spinner absolute />}
			{title && (
				<Header filterModalMobile={filterModalMobile}>
					<Title>{title}</Title>
					{headerIcon}
				</Header>
			)}
			{!title && <Close onClick={onReject} absolute />}
			<Content>{children}</Content>
			{haveFooter && (
				<Footer buttonsPosition={buttonsPosition}>
					<If predicate={ButtonComponents === null}>
						{onReject && rejectText && (
							<Button onClick={onReject}>{rejectText} </Button>
						)}
						{onConfirm && (
							<Button
								canConfirm={!!canConfirm}
								disabled={!canConfirm}
								onClick={onConfirm}
							>
								{confirmText}
							</Button>
						)}
					</If>
          <If predicate={ButtonComponents !== null}>
            {ButtonComponents}
          </If>

				</Footer>
			)}
		</StyledModal>
	);
};

Modal.propTypes = {
	rejectText: PropTypes.string,
	confirmText: PropTypes.string,
	canConfirm: PropTypes.bool,
	isLoading: PropTypes.bool,
	buttonsPosition: PropTypes.string,
	title: PropTypes.string,
	isOpen: PropTypes.bool,
	onReject: PropTypes.func,
	onConfirm: PropTypes.func,
	filterModalMobile: PropTypes.bool,
  ButtonComponents: PropTypes.object,
};

export default Modal;
