import PropTypes from 'prop-types';
import React, { Component } from 'react';

import { withRootContext } from 'context/withRootContext';
import HeaderNotification from './HeaderNotification';
import NavContent from './NavContent';
import NavMobile from './NavMobile';
import Provider from 'context/QuizContext';

import './Navigation.css';

const NAVIGATION_OFFSET = 180;

class Navigation extends Component {
	static propTypes = {
		isScrolled: PropTypes.func,
		router: PropTypes.object,
		context: PropTypes.shape({
			actions: PropTypes.object.isRequired,
			state: PropTypes.object.isRequired
		})
	};

	constructor(props) {
		super(props);
		this.state = {
			windowWidth: window.innerWidth,
			user: null
		};
		this.handler = this.scrollHandler.bind(this);
		this.resize = this.handleResize.bind(this);
	}

	componentDidMount() {
		window.addEventListener('scroll', this.handler);
		window.addEventListener('resize', this.resize);
	}

	componentWillUnmount() {
		window.removeEventListener('scroll', this.handler);
		window.removeEventListener('resize', this.resize);
	}

	handleResize() {
		this.setState({ windowWidth: window.innerWidth });
	}

	scrollHandler() {
		const isDynamicCurrent = window.pageYOffset > NAVIGATION_OFFSET;
		const { isDynamic } = this.props.context.state;

		if (isDynamic !== isDynamicCurrent) {
			const { toggleIsDynamic } = this.props.context.actions;
			toggleIsDynamic(isDynamicCurrent);
		}
	}

	render() {
		const { windowWidth, user } = this.state;
		const { isDynamic } = this.props.context.state;
		const { setQuizProgress } = this.props.context.actions;

		const navView = isDynamic
			? `navbar-fixed-top navbar-dynamic visible animate__animated animate__slideInDown`
			: `top-nav`;

		return (
			<React.Fragment>
				{windowWidth < 1025 && <NavMobile keyProp="dynamic" {...this.props} />}
				{windowWidth >= 1025 && (
					<div className="navigation">
						<nav className={`navbar navbar-default ${navView}`}>
							<HeaderNotification />
							<Provider user={user} setQuizProgress={setQuizProgress}>
								<NavContent keyProp="dynamic" {...this.props} />
							</Provider>
						</nav>
					</div>
				)}
			</React.Fragment>
		);
	}
}

export default withRootContext(Navigation);
