const fonts = {
	default: '"overlock-regular", "Helvetica", "Arial", sans-serif',
	black: '"overlock-black", "Helvetica", "Arial", sans-serif',
	bold: '"overlock-bold", "Helvetica", "Arial", sans-serif'
};

const theme = {
	'$container-max-widths': {
		sm: '540px !important',
		md: '720px !important',
		lg: '960px !important',
		xl: '1400px !important'
	},
	colors: {
		background: '#fff',
		text: '#adadad',
		activeText: '#000000',
		primary: '#9c27b0',
		link: '#0e88a2',
		warning: '#ff9800',
		danger: '#db0058',
		success: '#4caf50',
		info: '#00acc1',
		rose: '#e91e63',
		gray: '#999999'
	},
	device: {
		mMobile: `(max-width: 480px)`,
		tabletUp: `(min-width: 768px)`,
		tabletOnly: `(min-width: 768px) and (max-width: 1024px)`,
		mtablet: `(max-width: 767px)`,
		laptopUp: `(min-width: 1024px)`,
		mlaptop: `(max-width: 1024px)`,
		mDesktop: `(max-width: 1200px)`,
		mlaptopL: `(max-width: 1440px)`,
		laptopLUp: `(min-width: 1441px)`
	},
	fonts,

	boxShadow: {
		strong: {
			primary: '0 10px 50px 0 rgba(0, 0, 0, 0.1)',
			info:
				'0 10px 50px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(0, 188, 212, 0.4)',
			success:
				'0 10px 50px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(76, 175, 80, 0.4)',
			warning:
				'0 10px 50px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(255, 152, 0, 0.4)',
			danger:
				'0 10px 50px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4)',
			rose:
				'0 10px 50px 0 rgba(0,0,0,0.14), 0 7px 10px -5px rgba(233,30,99,0.4)',
			gray:
				'0 10px 50px 0 rgba(0, 0, 0, 0.14), 0 7px 10px -5px rgba(244, 67, 54, 0.4)'
		},
		light: {
			primary: '0 3px 42px 0 rgba(0, 0, 0, 0.15)'
		}
	},
	primaryFont: {
		fontFamily: `${fonts.default}`,
		fontWeight: 400,
		lineHeight: '1.5em'
		// fontSize: '10px',
	},
	noSelect: `
    -webkit-touch-callout: none;
      -webkit-user-select: none;
       -khtml-user-select: none;
         -moz-user-select: none;
          -ms-user-select: none;
              user-select: none;
                  `,
	get formElementFont() {
		return {
			...this.primaryFont,
			color: '#fff',
			fontWeight: '400',
			'::placeholder': {
				fontWeight: 400,
				opacity: 1,
				color: '#aaaaaa'
			}
		};
	}
};

// global theme related styles
// as we do not use styled-components to define global styles, this is just a blank template for the future
// todo, migrate theme related globals from css

const styleGuide = {
	// html: {
	//   fontSize: '10px',
	// },
	// body: {
	//   fontSize: '10px',
	//   backgroundColor: theme.colors.background,
	//   color: theme.colors.text,
	// },
	// h1: {
	//   fontSize: '4.2rem',
	//   lineHeight: '1.15',
	//   fontWeight: '300',
	//   margin: '2.8rem 0',
	// },
	// h2: {
	//   fontSize: '3.6rem',
	//   lineHeight: '1.5',
	//   fontWeight: '300',
	//   margin: '3rem 0',
	// },
	// h3: {
	//   fontSize: '2.5rem',
	//   lineHeight: '1.4',
	//   fontWeight: '300',
	//   margin: '2rem 0 1rem 0',
	// },
	// h4: {
	//   fontSize: '1.8rem',
	//   lineHeight: '2.5',
	//   fontWeight: '300',
	//   margin: '1rem 0',
	// },
	// h5: {
	//   fontSize: '1.7rem',
	//   lineHeight: '2.4',
	//   fontWeight: '300',
	//   margin: '1rem 0',
	// },
	// h6: {
	//   fontSize: '1.1rem',
	//   lineHeight: '1.6',
	//   fontWeight: '500',
	//   margin: '1rem 0',
	// },
	// p: {
	//   fontSize: '1.4rem',
	//   lineHeight: '1.3',
	//   fontWeight: '300',
	//   margin: '0 0 1rem 0',
	// },
	// blockquote: {
	//   margin: '0 0 2rem 0',
	//   padding: '1rem 2rem',
	//   fontSize: '1.7rem',
	//   borderLeft: '5px solid #eee',
	//   lineHeight: '1.5',
	//   fontWeight: '300',
	// },
	// 'blockquote p': {
	//   margin: '0 0 1rem',
	//   fontSize: '1.7rem',
	//   fontStyle: 'italic',
	// },
	// 'blockquote small': {
	//   color: theme.colors.gray,
	//   display: 'block',
	//   fontSize: '0.8em',
	//   lineHeight: '1.4',
	// },
	// a: {
	//   color: theme.colors.link,
	//   textDecoration: 'none',
	// },
	// 'a:hover': {
	//   color: theme.colors.link,
	//   textDecoration: 'none',
	// },
	// 'a:focus': {
	//   color: theme.colors.link,
	//   textDecoration: 'none',
	// },
};

export default {
	...theme,
	styleGuide
};
