import styled from 'styled-components';

export const Wrapper = styled.div`
  display: block;
  height: 100%;
  mask-repeat: no-repeat;
  mask-size: contain;
  mask-position: center center;
  background-color: ${props => props.color};
  webkit-mask-image: url("${props => props.mask}");
  mask-image: url("${props => props.mask}");
`;

export const Placeholder = styled.img`
  width: 100%;
  min-width: 20px;
  opacity: 0;
  max-height: 100%;
  max-width: 70px;
`;
