import React, { useState, useCallback, useEffect, useRef } from 'react';
import { Prompt } from 'react-router-dom';

import { add, levels } from 'components/Notification/Notifications';
import { HTTP_CODES, ERROR_MESAGES, MEDIA_BREAK_POINTS } from 'utils/constants';

export const useBoolean = (initialValue) => {
	const [value, setValue] = useState(initialValue);

	const setTrue = useCallback(() => {
		setValue(true);
	}, []);

	const setFalse = useCallback(() => {
		setValue(false);
	}, []);

	return [value, setTrue, setFalse];
};

export const useServerErrors = () => {
	const parseServerErrors = (error, fieldMappings = {}) => {
		const errors = [];

		if (error && error.status === HTTP_CODES.BAD_REQUEST) {
			for (const field in error.data) {
				const fieldName = fieldMappings[field] || field;
				const fieldErrors = error.data[field];

				fieldErrors.forEach((err) => errors.push(`${fieldName}: ${err}`));
			}

			return errors;
		}

		return [ERROR_MESAGES.WHOOPS];
	};

	const handleServerErrors = useCallback((error, fieldMappings) => {
		const handleErrors = (error, fieldMappings = {}) => {
			const errors = parseServerErrors(error, fieldMappings);

			errors.forEach((error) => {
				add(error, levels.ERROR);
			});
		};

		handleErrors(error, fieldMappings);
	}, []);

	return {
		handleServerErrors
	};
};

export const useWindowWidth = () => {
	const isBrowser = typeof window !== 'undefined';
	const [width, setWidth] = useState(isBrowser ? window.innerWidth : 0);
	useEffect(() => {
		if (!isBrowser) return false;
		const handleResize = () => setWidth(window.innerWidth);
		window.addEventListener('resize', handleResize);
		return () => {
			window.removeEventListener('resize', handleResize);
		};
	});

	return width;
};

export const useCheckMobileScreen = () => {
	const [width, setWidth] = useState(window.innerWidth);
	const handleWindowSizeChange = () => {
		setWidth(window.innerWidth);
	};

	useEffect(() => {
		window.addEventListener('resize', handleWindowSizeChange);
		return () => {
			window.removeEventListener('resize', handleWindowSizeChange);
		};
	}, []);

	return width <= MEDIA_BREAK_POINTS.MOBILE;
};

export const useChangePrompt = ({ promptMessage }) => {
	const [activePrompt, setPromptState] = useState(false);
	const changeActivePrompt = (isPromptActive) => setPromptState(isPromptActive);

	const ChangePrompt = useCallback(
		() => <Prompt when={activePrompt} message={promptMessage} />,
		// eslint-disable-next-line
		[activePrompt]
	);

	return {
		changeActivePrompt,
		ChangePrompt
	};
};

export const usePrevious = (value) => {
  const ref = useRef();
  useEffect(() => {
    ref.current = value;
  });
  return ref.current;
};


export const useHasValuesChanged = (val) => {
  const prevVal = usePrevious(val)
  return prevVal !== val
};
