import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';

import CircleCheckbox from 'components/New/Basic/CircleCheckbox/CircleCheckbox';
import ColorImage from 'components/New/Basic/ColorImage/ColorImage';
import ReactTooltip from 'react-tooltip';
import useModal from 'components/New/Modal/useModal';
import {
	ChangeSelectionWrapper,
	CircleBoxWrapper,
	ItemIconsWrapper,
	LinkButton,
	SelectedItemHeader,
	SelectedItemDescription
} from 'components/Persona/Persona.styled.js';
import { BLOCK_TYPE } from 'utils/constants';

const ChangeResult = ({
	quizProgress,
	attributes,
	blockType,
	changeSelectedItem
}) => {
	const [quizAttributeSelections, setQuizAttributeSelections] = useState(null);
	const [selectedItem, setSelectedItem] = useState(quizProgress);
	const { Modal, showModal, hideModal, isOpen } = useModal();
	const attributeSelectionItems =
		quizAttributeSelections && quizAttributeSelections[blockType].items;
	const blockTypeLowerCase = blockType.toLowerCase();

	useEffect(() => {
		const calculateQuizItemSelections = (selectedItem, attributes) => {
			if (blockType === BLOCK_TYPE.STYLE) {
				return {
					[BLOCK_TYPE.STYLE]: {
						title: BLOCK_TYPE.STYLE,
						items: attributes.style.map((item) => ({
							id: item.id,
							name: item.name,
							url: item.icon,
							description: item.description,
							checked: selectedItem?.style && item.id === selectedItem.style.id
						}))
					}
				};
			}

			if (blockType === BLOCK_TYPE.ARTISTRY) {
				return {
					[BLOCK_TYPE.ARTISTRY]: {
						title: BLOCK_TYPE.ARTISTRY,
						items: attributes.artistry.map((item) => ({
							id: item.id,
							name: item.name,
							url: item.icon,
							description: item.description,
							checked:
								selectedItem?.artistry && item.id === selectedItem.artistry.id
						}))
					}
				};
			}

			if (blockType === BLOCK_TYPE.COLOR) {
				return {
					[BLOCK_TYPE.COLOR]: {
						title: BLOCK_TYPE.COLOR,
						items: attributes.color.map((item) => ({
							id: item.id,
							name: item.name,
							color: item.color,
							description: item.description,
							checked: selectedItem?.color && item.id === selectedItem.color.id
						}))
					}
				};
			}
		};

		const quizSelections = calculateQuizItemSelections(
			selectedItem,
			attributes
		);

		setQuizAttributeSelections(quizSelections);
	}, [selectedItem, attributes, blockType, isOpen]);

	useEffect(() => {
		setSelectedItem(quizProgress);
	}, [quizProgress]);

	const applySelectedItem = async () => {
		await changeSelectedItem({
			...(selectedItem?.artistry && { artistry_id: selectedItem.artistry.id }),
			...(selectedItem?.style && { style_id: selectedItem.style.id }),
			...(selectedItem?.color && { color_id: selectedItem.color.id })
		});
		hideModal();
	};

	const handleSelectionChange = (item) => {
		setSelectedItem({
			...selectedItem,
			[blockTypeLowerCase]: {
				id: item.id,
				name: item.name,
				description: item.description,
				...(item.url
					? {
							url: item.url
					  }
					: {
							color: item.color
					  })
			}
		});
	};

	const closeSelectionModal = () => {
		setSelectedItem(quizProgress);
		hideModal();
	};

	return (
		<>
			<LinkButton onClick={showModal}>Change</LinkButton>
			<Modal
				title={`Change your ${blockType}`}
				rejectText="No"
				confirmText="Yes"
				onReject={closeSelectionModal}
				onConfirm={applySelectedItem}
				filterModalMobile
			>
				<ChangeSelectionWrapper>
					<ItemIconsWrapper
						fillspace={
							attributeSelectionItems && attributeSelectionItems.length > 5
						}
					>
						{attributeSelectionItems &&
							attributeSelectionItems.map((groupItem, groupItemIndex) => (
								<CircleBoxWrapper
									key={`${groupItem.id}-${groupItemIndex}`}
									data-tip={groupItem.name}
								>
									<CircleCheckbox
										key={`${groupItem.id}-${groupItemIndex}`}
										checked={groupItem.checked}
										onClick={() => handleSelectionChange(groupItem)}
									>
										{groupItem.url ? (
											<img src={groupItem.url} alt="" />
										) : (
											<ColorImage color={groupItem.color} />
										)}
									</CircleCheckbox>
									<ReactTooltip globalEventOff="click" />
								</CircleBoxWrapper>
							))}
					</ItemIconsWrapper>
					<SelectedItemHeader>
						{selectedItem[blockTypeLowerCase]?.name}
					</SelectedItemHeader>
					<SelectedItemDescription
						dangerouslySetInnerHTML={{
							__html: selectedItem[blockTypeLowerCase]?.description
						}}
					/>
				</ChangeSelectionWrapper>
			</Modal>
		</>
	);
};

ChangeResult.propTypes = {
	quizProgress: PropTypes.shape({
		artistry: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			url: PropTypes.string
		}),
		color: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			color: PropTypes.string.isRequired
		}),
		style: PropTypes.shape({
			id: PropTypes.number.isRequired,
			name: PropTypes.string.isRequired,
			url: PropTypes.string
		})
	}),
	attributes: PropTypes.object,
	blockType: PropTypes.oneOf([
		BLOCK_TYPE.COLOR,
		BLOCK_TYPE.STYLE,
		BLOCK_TYPE.ARTISTRY
	]),
	changeSelectedItem: PropTypes.func.isRequired
};

export default ChangeResult;
