import React from 'react';
import PropTypes from 'prop-types';

import { Wrapper, Placeholder } from "./ColorImage.styled";
import DefaultMask from './ColorImage.png';
/**
 * Colorized alpha mask, this component is block sized, contain all available parent space.
 */
const ColorImage = ({
  mask,
  color,
}) => {
  return (
    <Wrapper
      color={color}
      mask={mask || DefaultMask}
      className="ColorImage"
      style={{}}
    >
      <Placeholder src={mask || DefaultMask} />
    </Wrapper>
  );
};

ColorImage.defaultProps = {
  color: '#000',
};

ColorImage.propTypes = {
  /**
   * Alpha mask
   */
  mask: PropTypes.string,
  /**
   * Color to use
   */
  color: PropTypes.string,
};

export default ColorImage;
