import React, { useContext } from 'react';
import { RootContext } from 'context/RootContext';

import {
	Title,
	StyledButton,
	Text
} from 'components/NewsletterPopup/NewsletterPopup.styled';
import useSubscribeNewsletter from './useSubscribeNewsletter';

const Intro = ({ nextStep, step }) => {
	const rootContext = useContext(RootContext);
	const { user } = rootContext.state;

	const { isLoading, subscribeAsync } = useSubscribeNewsletter();

	const handleNextStep = async () => {
		if (user)
			await subscribeAsync(true, {
				email: user.email,
				first_name: '',
				last_name: ''
			});
		nextStep(user ? step + 1 : step);
	};

	return (
		<>
			<Title>
				{user ? `Hey ${user?.first_name},` : null}
				<br />
				Would you like to sign up for our newsletter?
			</Title>
			<Text>
				Subscribe to our newsletter to get all the news, updates and anything
				related to us.
			</Text>
			<div>
				<StyledButton disabled={isLoading} onClick={handleNextStep}>
					Sign me up
				</StyledButton>
			</div>
		</>
	);
};

export default Intro;
