import React, { useContext, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';

import { isSameDay } from 'date-fns';
import { RootContext } from 'context/RootContext';
import {
	get as localStorageGet,
	save as localStorageSave
} from 'utils/localstorageService';
import { LOCALSTORAGE_KEYS } from 'utils/constants';

import Intro from 'components/NewsletterPopup/Intro';
import RegisterUser from 'components/NewsletterPopup/RegisterUser';
import {
	StickySection,
	Close,
	Block,
	Title,
	Text,
	LinkButton
} from 'components/NewsletterPopup/NewsletterPopup.styled';

const UnsubscribeText = () => {
	return (
		<>
			You have subscribed to the newsletter! If you want to unsubscribe at any
			time click
			<Link to="/settings" style={{ fontWeight: 'bold' }}>
				{' '}
				here
			</Link>{' '}
			to go to the settings and to unsubscribe.
		</>
	);
};

const Success = () => {
	const rootContext = useContext(RootContext);
	const { user } = rootContext.state;

	return (
		<>
			<Title>
				Thank you! <br />
				You will hear from us soon.
			</Title>
			<Text>
				{user ? (
					<UnsubscribeText />
				) : (
					'You have subscribed to the newsletter! If you want to unsubscribe at any time unsubscribe via your mail.'
				)}
			</Text>
		</>
	);
};

const NewsletterPopup = () => {
	const rootContext = useContext(RootContext);
	const { user } = rootContext.state;

	const [showNewsletterPopup, setShowNewsletterPopup] = useState(false);
	const [step, setStep] = useState(1);

	useEffect(() => {
		const newsletterSubscribed = localStorageGet(
			LOCALSTORAGE_KEYS.NEWSLETTER_SUBSCRIBED
		);

		if (user?.has_mailchimp_subscription) {
			localStorageSave(LOCALSTORAGE_KEYS.NEWSLETTER_SUBSCRIBED, {
				closedAt: null,
				newsletterSubscription: true
			});
			setShowNewsletterPopup(false);
			return;
		}

		const checkIfSameDay = isSameDay(
			newsletterSubscribed?.closedAt,
			Date.now()
		);

		if (!checkIfSameDay && !newsletterSubscribed?.newsletterSubscription) {
			setShowNewsletterPopup(true);
			localStorageSave(LOCALSTORAGE_KEYS.NEWSLETTER_SUBSCRIBED, {
				closedAt: null,
				newsletterSubscription: false
			});
		}
	}, [user]);

	const handlePopupClose = () => {
		const newsletterSubscribed = localStorageGet(
			LOCALSTORAGE_KEYS.NEWSLETTER_SUBSCRIBED
		);

		if (!newsletterSubscribed?.newsletterSubscription) {
			localStorageSave(LOCALSTORAGE_KEYS.NEWSLETTER_SUBSCRIBED, {
				closedAt: Date.now(),
				newsletterSubscription: false
			});
		}

		setShowNewsletterPopup(false);
	};

	const nextStep = (step) => {
		setStep(step + 1);
	};

	const Steps = () => {
		switch (step) {
			case 1:
				return <Intro nextStep={nextStep} step={step} />;
			case 2:
				return <RegisterUser nextStep={nextStep} step={step} />;
			case 3:
				return <Success />;
			default:
				return null;
		}
	};

	return (
		<>
			{showNewsletterPopup && (
				<StickySection>
					<Block>
						<Close onClick={() => handlePopupClose()} />
					</Block>
					<img src="/images/rebranding/svgs/3758565.svg" alt="Mail icon" />
					<Steps />
					{step !== 3 && (
						<LinkButton onClick={() => handlePopupClose()}>
							Maybe Later
						</LinkButton>
					)}
				</StickySection>
			)}
		</>
	);
};

export default NewsletterPopup;
