import axios from 'axios';

import { HTTP_CODES } from 'utils/constants';
import * as AuthService from './authService';

const instance = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL
});

export const attachTokenToHeaders = () => {
  const token = AuthService.getToken();

  if (token && token.access) {
    instance.defaults.headers.common['Authorization'] = `Bearer ${token.access}`;
  } else {
    delete instance.defaults.headers.common['Authorization'];
  }
};

export const clearTokens = () => {
  delete instance.defaults.headers.common['Authorization'];
};

export const setupInterceptors = () => {
  instance.interceptors.response.use(
    (response) => {
      return response;
    },
    (error) => {
      if (error.response && error.response.status === HTTP_CODES.UNAUTHORIZED && !window.location.href.includes('/login')) {
          AuthService.logout();
          setTimeout(() => {
            const { pathname } = window.location;
            const redirectPath = pathname ? `?redirectPath=${pathname}` : '';

            window.location.href = `/login${redirectPath}`;
          }, 1000);
      }
      return Promise.reject(error.response);
    }
  );
};

export default instance;
