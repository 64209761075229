import Intro from 'components/Quiz/Intro';
import SectionSelection from 'components/Quiz/SectionSelection';
import Recap from 'components/Quiz/Recap';
import { STEP_HANDLERS } from 'utils/constants';

export const MAIN_FLOW_STEPS = [
  {
    component: Intro
  },
  {
    component: Recap
  }
];

export const STYLE_STEPS = [
  {
    component: SectionSelection,
    handle: STEP_HANDLERS.STYLE,
    params: {
      title: 'Style',
      description: 'Follow your instinctive responses and select one.'
    }
  },
];

export const COLOR_STEPS = [
  {
    component: SectionSelection,
    handle: STEP_HANDLERS.COLOR_GROUPS,
    params: {
      title: 'Color',
      description: 'Follow your instinctive responses and select one.'
    }
  },
  {
    component: SectionSelection,
    handle: STEP_HANDLERS.COLOR_ELEMENT,
    params: {
      title: 'Color',
      description: 'Follow your instinctive responses and select one.'
    }
  },
];

export const ARTISTRY_STEPS = [
  {
    component: SectionSelection,
    handle: STEP_HANDLERS.ARTISTRY,
    params: {
      title: 'Artistry',
      description: 'Follow your instinctive responses and select one.'
    }
  },
];
