import React, { useCallback, useState } from 'react';

import Modal from './Modal';

const useModal = ({ isOpen = false, isLoading = false } = {}) => {
	const [isOpenState, setIsOpenState] = useState(isOpen);
	const [isLoadingState, setIsLoadingState] = useState(isLoading);
	const showModal = () => setIsOpenState(true);
	const hideModal = () => setIsOpenState(false);
	const showLoader = () => setIsLoadingState(true);
	const hideLoader = () => setIsLoadingState(false);
	const MyModal = useCallback(
		({ onReject, ...props }) =>
			isOpenState ? (
				<Modal
					isOpen
					onReject={onReject || hideModal}
					isLoading={isLoadingState}
					{...props}
				/>
			) : null,
		// eslint-disable-next-line
		[isOpenState]
	);

	return {
		showModal,
		hideModal,
		showLoader,
		hideLoader,
		isOpen: isOpenState,
		isLoading: isLoadingState,
		Modal: MyModal
	};
};

export default useModal;
