import React from 'react';

import styled from 'styled-components';

const Wrapper = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	gap: 3px;
	width: 66px;
	height: 23px;
	border-radius: 8px;
	background-color: ${({ theme }) => theme.colors.link};
	color: ${({ theme }) => theme.colors.background};
	font-size: 11px;
	cursor: pointer;
`;

const ResumeIcon = (props) => {
	return (
		<Wrapper {...props}>
			<svg
				xmlns="http://www.w3.org/2000/svg"
				width="7.42"
				height="9.46"
				viewBox="0 0 7.42 9.46"
			>
				<defs></defs>
				<path
					id="Icon_ionic-md-play"
					data-name="Icon ionic-md-play"
					style={{ fill: '#fff' }}
					d="M6.75,3.656v9.46l7.42-4.73Z"
					transform="translate(-6.75 -3.656)"
				/>
			</svg>
			<div>RESUME</div>
		</Wrapper>
	);
};

export default ResumeIcon;
