import React from 'react';

import { Consumer } from './QuizContext';

export const withQuizContext = (Component) => {
  const withQuizContext = (props) => (
    <Consumer>
      {context => <Component {...props} quizContext={context} />}
    </Consumer>
  );

  withQuizContext.displayName = `withQuizContext(${Component.displayName || Component.name}`;

  return withQuizContext;
};
