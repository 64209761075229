export const TOKEN_KEY = 'JWT-TOKEN';
export const BASE_URL = process.env.REACT_APP_BASE_URL;
export const GOOGLE_AUTH_URL = 'auth/login/google-oauth2/?next=auth-redirect';
export const FACEBOOK_AUTH_URL = 'auth/login/facebook/?next=auth-redirect';
export const TWITTER_AUTH_URL = 'auth/login/twitter/?next=auth-redirect';
export const GOOGLE_OAUTH2_KEY =
	process.env.REACT_APP_SOCIAL_AUTH_GOOGLE_OAUTH2_KEY;
export const FACEBOOK_KEY = process.env.REACT_APP_SOCIAL_AUTH_FACEBOOK_KEY;
const APP_NAME = 'tao-dress';
const LOCALSTORAGE_PREFIX = 'ls';

export const HTTP_CODES = {
	BAD_REQUEST: 400,
	UNAUTHORIZED: 401,
	OK: 200
};

export const REDIRECT_URL_PATTERN = /\?redirectPath=(.*)/;

export const SUBFLOW = {
	STYLE: 'style',
	COLOR: 'color',
	ARTISTRY: 'artistry'
};

export const STEP_HANDLERS = {
	ALPHA_OMEGA: 'style::alpha-omega',
	STYLE: 'style::selection',
	COLOR_GROUPS: 'color::element-groups',
	COLOR_ELEMENT: 'color::element',
	ARTISTRY: 'artistry::selection'
};

export const SECTION_API = {
	ALPHA_OMEGA: 'style-persona',
	STYLE: 'style',
	COLOR_GROUPS: 'color-groups',
	COLOR_ELEMENT: 'color-element',
	ARTISTRY: 'artistry'
};

export const PAGINATION = {
	PAGE_SIZE: 6
};

export const LOCALSTORAGE_KEYS = {
	BLOG_FILTER: `${APP_NAME}-${LOCALSTORAGE_PREFIX}-blog-filter`,
	ANON_QUIZ: `${APP_NAME}-${LOCALSTORAGE_PREFIX}-anon-user`,
	SAW_LEARN: `${APP_NAME}-${LOCALSTORAGE_PREFIX}-saw-learn`,
	LAST_LEARN_ARTICLE: `${APP_NAME}-${LOCALSTORAGE_PREFIX}-last-learn-article`,
	PRIVACY_POLICY: `${APP_NAME}-${LOCALSTORAGE_PREFIX}-privacy-policy`,
	LAST_NOTIFICATIONS_OPENED: `${APP_NAME}-${LOCALSTORAGE_PREFIX}-last-notification-opened`,
	NEWSLETTER_SUBSCRIBED: `${APP_NAME}-${LOCALSTORAGE_PREFIX}-newsletter-subscribed`
};

export const ERROR_MESAGES = {
	WHOOPS: 'Whoops, something went wrong'
};

export const IMAGE_NOT_FOUND = '/images/rebranding/imageNotFound.png';

export const BLOG_TYPES = {
	NEWS: 1,
	POSTS: 2,
	ALL: 3
};

export const SPLASH_VIDEO_URL =
	'https://res.cloudinary.com/taod-dev/video/upload/v1548770900/static/videos/splash-video-2.mp4';

export const ASSET_TYPES = {
	STYLE: 'style',
	ARTISTRY: 'artistry',
	COLOR: 'color',
	COLOUR: 'colour'
};

export const BLOCK_TYPE = {
	STYLE: 'Style',
	ARTISTRY: 'Artistry',
	COLOR: 'Color'
};

export const UNSAVED_CHANGES_PROMPT_MESSAGE =
	'There are some unsaved changes. Are you sure you want to continue?';

export const BLOGS_SECTION_ORIGIN = {
	DISCOVER: 'discover',
	DASHBOARD: 'dashboard'
};

export const MEDIA_BREAK_POINTS = {
	MOBILE: 992
};
