import API from 'utils/apiConfig';

const getCountriesValues = async () => {
  const response = await API.get(`api/quiz/analytics/locations/`);
  return await response.data;
};

const getAssetsValues = async () => {
  const response = await API.get(`api/quiz/analytics/assets/`);
  return await response.data;
};

const getQuizAnalyticsTotals = async (itemType, filters) => {
  const response = await API.get(`api/quiz/analytics/totals/${itemType}/`, { params: filters });
  return await response.data;
};

const getAnalyticsComparisonTotals = async (
  sourceComparatorType,
  sourceComparatorId,
  targetComparatorType,
  filters
) => {
  const response = await API.get(
    `api/quiz/analytics/distribution/${sourceComparatorType}/${sourceComparatorId}/compare/${targetComparatorType}/`,
    { params: filters }
  );
  return await response.data;
};

export default {
  getCountriesValues,
  getAssetsValues,
  getQuizAnalyticsTotals,
  getAnalyticsComparisonTotals
};
