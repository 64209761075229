import React from 'react';
import PropTypes from 'prop-types';
import { QuizChoice, QuestionImage, ColorOptions, OptionsWrapper } from './Question.styled';

const Option = ({ option, makeChoice, isColor, choiceId, section }) => {
	const ImageOption = () => {
		return (
			<QuizChoice
				isSelected={`${choiceId === option.id ? 'selected' : ''}`}
				isStyleSection={section === 'style' || null}
			>
				<QuestionImage
					alt={`Quiz choice`}
					src={`${option.url}`}
					onClick={makeChoice.bind(null, option.id)}
					isStyleSection={section === 'style' || null}
				/>
			</QuizChoice>
		);
	};

	const ColorOption = () => {
		return (
			<QuizChoice
				isSelected={`${choiceId === option.id ? 'selected' : ''}`}
				onClick={makeChoice.bind(null, option.id)}
				colorOption
			>
				{option.colors.map((color, i) => {
					const style = { style: { backgroundColor: color } };
					return <ColorOptions key={i} {...style}/>;
				})}
			</QuizChoice>
		);
	};

	return isColor ? <ColorOption /> : <ImageOption />;
};

Option.propTypes = {
	option: PropTypes.object.isRequired,
	makeChoice: PropTypes.func.isRequired,
	isColor: PropTypes.bool.isRequired,
	choiceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
	section: PropTypes.string.isRequired
};

const Question = ({ section, question, makeChoice, choiceId }) => {
	return (
		<OptionsWrapper question={question?.length}>
			{question.map((option, i) => {
				return (
					<Option
						key={i}
						option={option}
						makeChoice={makeChoice}
						isColor={option.url === undefined}
						choiceId={choiceId}
						section={section}
					/>
				);
			})}
		</OptionsWrapper>
	);
};

Question.propTypes = {
	question: PropTypes.array.isRequired,
	makeChoice: PropTypes.func.isRequired,
	section: PropTypes.oneOf(['style', 'color', 'artistry']).isRequired, // had to hardcode, as oneOf does not seem to work with imported values
	choiceId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default Question;
