import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';

import { quizApi } from 'api/index';
import { add, levels } from 'components/Notification/Notifications';
import {
	LOCALSTORAGE_KEYS,
	SECTION_API,
	STEP_HANDLERS,
	SUBFLOW
} from 'utils/constants';
import {
	get as localStorageGet,
	remove as localStorageRemove,
	save as localStorageSave
} from 'utils/localstorageService';
import {
	ARTISTRY_STEPS,
	COLOR_STEPS,
	MAIN_FLOW_STEPS,
	STYLE_STEPS
} from 'utils/quizStepsConfig';
import { onImageLoaded } from '../components/LearnManagement/utils';

const Context = React.createContext();
const RETAKE_QUIZ = 'retake';
const NEXT_INDEX_STEP = 1;
const MAX_STYLE_QUESTIONS = 4;

class Provider extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: true,
			hasStartedQuiz: localStorageGet('hasStartedQuiz') || false,
			step: MAIN_FLOW_STEPS[0],
			stepIndex: 0,
			questions: [],
			section: null,
			results: {
				style: null,
				color: null,
				artistry: null
			},
			anonUser: null,
			allStyleChoices: [],
			restChoices: [],
			presentedChoices: {},
			currentStyleRound: null,
			currentStyleStage: null
		};

		this.steps = [];
		this.colorGroupResult = null;
	}

	componentDidMount() {
		const { history, match } = this.props;

		if (match.params.query === RETAKE_QUIZ) {
			this.retakeQuiz();
			history.replace('/quiz');
			return;
		}

		this.fetchUserProgress();
	}

	setQuizHasStarted = () => {
		if (this.state.hasStartedQuiz === true) return;

		this.setState({ hasStartedQuiz: true });
		localStorageSave('hasStartedQuiz', true);
	};

	resetQuizHasStarted = () => {
		if (!this.state.hasStartedQuiz) return;

		this.setState({ hasStartedQuiz: false });
		localStorageSave('hasStartedQuiz', false);
	};

	fetchUserProgress = async () => {
		const { user } = this.props;

		try {
			const progress = user
				? await quizApi.getQuizProgress()
				: await this.anonUserProgress();
			this.setSubflowState(progress);
			if (this.isQuizCompleted(progress)) {
				this.nextScreen();
			}
		} catch (error) {
			console.error(error && error.data ? error.data : error);
			add('Error! Could not fetch user information ', levels.ERROR);
		}

		this.setState({ isLoading: false });
	};

	anonUserProgress = async () => {
		const username = localStorageGet(LOCALSTORAGE_KEYS.ANON_QUIZ);

		if (username) {
			this.setState({ anonUser: username });
			const progress = await quizApi.getQuizProgressByUsername(username);

			// if there is no result we can assume DB user was removed
			if (Object.keys(progress).length === 0) {
				localStorageRemove(LOCALSTORAGE_KEYS.ANON_QUIZ);
				this.setState({ anonUser: null });

				return {};
			}

			return progress;
		}

		return {};
	};

	getAnonQuizUser = () => {
		const { user } = this.props;
		return !user && localStorageGet(LOCALSTORAGE_KEYS.ANON_QUIZ);
	};

	isQuizCompleted = (results) => {
		return results.style && results.color && results.artistry;
	};

	//#region State navigation
	nextScreen = () => {
		const { results } = this.state;
		const { history } = this.props;
		if (!results.style) {
			this.startSubflow(SUBFLOW.STYLE);
		} else if (!results.artistry) {
			this.startSubflow(SUBFLOW.ARTISTRY);
		} else if (!results.color) {
			this.startSubflow(SUBFLOW.COLOR);
		} else {
			//this.steps = [...MAIN_FLOW_STEPS];
			this.setState(
				{
					//: this.steps[1],
					//stepIndex: NEXT_INDEX_STEP,
					section: null,
					results
				},
				() => history.push('/persona')
			);
		}
	};

	nextStep = async ({ answers }) => {
		let hasErrors = false;

		if (answers) {
			await this.handleStepResponses(answers)
				.catch((err) => {
					console.error(err && err.data);
					add('Whoops, something went wrong', levels.ERROR);
					hasErrors = true;
				})
				.finally(() => this.setState({ isLoading: false }));
		}

		if (hasErrors) return;

		const nextIndex = this.state.stepIndex + 1;
		const nextStepConfig = this.steps[nextIndex];

		let questions = [];

		if (nextIndex >= this.steps.length) {
			const nextIndex = NEXT_INDEX_STEP;
			this.setState({
				step: this.steps[nextIndex],
				stepIndex: nextIndex,
				questions
			});
			return;
		}

		this.setState({ isLoading: true });

		if (this.state.step.handle !== STEP_HANDLERS.STYLE) {
			// Special handling when fetching questions for STYLE section
			questions = await this.fetchArtistryAndColorQuestions(
				nextStepConfig
			).finally(() => this.setState({ isLoading: false }));
			this.setState({
				step: nextStepConfig,
				stepIndex: nextIndex,
				questions
			});
		} else {
			questions = await this.handleStyleQuestions([]).finally(() =>
				this.setState({ isLoading: false })
			);
			this.setState({
				step: nextStepConfig,
				stepIndex: nextIndex
			});
		}
	};

	fetchArtistryAndColorQuestions = (nextStepConfig) => {
		return new Promise((resolve) => {
			// load all step questions
			this.fetchQuestions(nextStepConfig.handle).then((data) => {
				// load first step
				const imagesToPreloadFirst = Array.isArray(data[0])
					? data[0].reduce((acc, cur) => {
							// check if element have url, add to loader
							if (cur.url) acc.push(cur.url);
							return acc;
					  }, [])
					: [];
				onImageLoaded(imagesToPreloadFirst).finally(() => {
					resolve(data);
				});
				// run through all other question and start image loading
				data.forEach((step) => {
					if (Array.isArray(step))
						step.forEach((stepItem) => {
							if (stepItem.url) {
								// create image object to preload data
								var img = new Image();
								img.src = stepItem.url;
							}
						});
				});
			});
		});
	};

	handleStyleQuestions = async (selectedChoices) => {
		// After each submittion of selected choices we should initialize the new questions.
		const { setQuizProgress } = this.props;
		const anonUsername = localStorageGet(LOCALSTORAGE_KEYS.ANON_QUIZ);
		const username = this.props?.user?.username || anonUsername;
		let styleQuestionsProgress = null;

		this.setState({ isLoading: true });

		try {
			styleQuestionsProgress = await this.fetchAndSaveStyleQuestions(
				selectedChoices,
				username
			);

			if (
				styleQuestionsProgress?.section_complete &&
				styleQuestionsProgress?.style_type
			) {
				const results = {
					style: {
						id: styleQuestionsProgress?.style_type?.id,
						name: styleQuestionsProgress?.style_type?.title,
						url: styleQuestionsProgress?.style_type?.icon,
						description: styleQuestionsProgress?.style_type?.description
					},
					color: this.state.results.color,
					artistry: this.state.results.artistry
				};
				setQuizProgress(results);
				this.setSubflowState(results);
				this.nextScreen();
			} else {
				this.prepareInitialStyleQuestions(styleQuestionsProgress);
			}
		} catch (err) {
			console.error(err && err.data);
			add('Whoops, something went wrong', levels.ERROR);
		}

		this.setState({ isLoading: false });

		return styleQuestionsProgress;
	};

	fetchAndSaveStyleQuestions = (selectedChoices, username) => {
		return new Promise((resolve, reject) => {
			// load all step questions
			quizApi
				.handleStyleSectionQuestions(selectedChoices, username)
				.then((data) => {
					// load first step
					const imagesToPreloadFirst = Array.isArray(data.new_choices)
						? data.new_choices.reduce((acc, cur) => {
								// check if element have url, add to loader
								if (cur.url) acc.push(cur.url);
								return acc;
						  }, [])
						: [];
					onImageLoaded(imagesToPreloadFirst).finally(() => {
						resolve(data);
					});
				})
				.catch((error) => {
					reject(error);
				});
		});
	};

	shuffleArray = (arr) => arr.sort(() => Math.random() - 0.5);

	prepareInitialStyleQuestions = (styleQuestionsProgress) => {
		const allStyleChoices = styleQuestionsProgress.new_choices;

		const choicesLength = Math.min(allStyleChoices.length, MAX_STYLE_QUESTIONS);
		const styleQuestions = allStyleChoices.slice(0, choicesLength);
		const questionIndex = 0;

		this.setState({
			isLoading: false,
			questions: styleQuestions,
			presentedChoices: {
				// Currently only refers to new round of questions.
				[questionIndex]: styleQuestions
			},
			restChoices: allStyleChoices.filter(
				(_, index) => index > choicesLength - 1
			),
			allStyleChoices: styleQuestionsProgress.new_choices,
			currentStyleRound: styleQuestionsProgress.current_round,
			currentStyleStage: styleQuestionsProgress.current_stage
		});
	};

	prepareRestStyleQuestions = (lastSelectedOption, questionIndex = 0) => {
		if (questionIndex === 0) {
			// For first question, we should call restart the already created selectedChoices
			this.getInitialStyleQuestions();
			return;
		}

		const { questions, restChoices, presentedChoices } = this.state;
		const nextQuestionsSlice = Math.min(
			restChoices.length,
			MAX_STYLE_QUESTIONS - 1
		);
		const nextQuestions = [...restChoices.slice(0, nextQuestionsSlice)];

		if (lastSelectedOption && questions.length > 0) {
			nextQuestions.push(lastSelectedOption);
		}

		const shuffledQuestions = this.shuffleArray(nextQuestions);

		this.setState((prevState) => ({
			...prevState,
			questions: shuffledQuestions,
			restChoices: restChoices.slice(nextQuestionsSlice),
			presentedChoices: {
				...presentedChoices,
				[questionIndex]: shuffledQuestions
			}
		}));

		return shuffledQuestions;
	};

	handleStyleSectionPreviousQuestions = (answers) => {
		if (!answers || answers.length === 0) return null;

		const lastAnswerIndex = answers.length - 1;
		const lastAnswer = answers[lastAnswerIndex];

		this.setState((prevState) => {
			const presentedChoices = prevState.presentedChoices;
			const actualQuestions = presentedChoices[answers.length];
			const previousQuestions = presentedChoices[lastAnswerIndex];

			const restChoices = [
				...prevState.restChoices,
				...actualQuestions.filter((question) => question.id !== lastAnswer)
			];

			if (answers.length !== 0) {
				delete presentedChoices[answers.length];
			}

			return {
				...prevState,
				questions: previousQuestions,
				presentedChoices,
				restChoices
			};
		});
	};

	setSubflowState = (results) => {
		this.setState({
			results,
			answers: []
		});
	};

	createAnonUser = async () => {
		this.setState({ isLoading: true });
		try {
      const hasAcceptedPolicy = localStorageGet(
				LOCALSTORAGE_KEYS.PRIVACY_POLICY
			)?.consent;
			const response = await quizApi.createAnonUser({has_accepted_policy: hasAcceptedPolicy});
			const username = response && response.username;

			if (!username) {
				throw new Error('Something went wrong, contact site administrator');
			}
			localStorageSave(LOCALSTORAGE_KEYS.ANON_QUIZ, username);
			this.setState({ anonUser: username });
		} catch (error) {
			console.error(error && error.data ? error.data : error);
			add('Error! Could not fetch user information ', levels.ERROR);
		}
		this.setState({ isLoading: false });
	};

	startSubflow = async (section = SUBFLOW.STYLE) => {
		const { user } = this.props;
		const { anonUser } = this.state;

		if (!user && !anonUser) {
			await this.createAnonUser();
		}

		const { results } = { ...this.state };
		let subflow = null;
		this.colorGroupResult = null;

		switch (section) {
			case SUBFLOW.STYLE:
				this.steps = [...STYLE_STEPS];
				subflow = SUBFLOW.STYLE;
				break;
			case SUBFLOW.COLOR:
				this.steps = [...COLOR_STEPS];
				subflow = SUBFLOW.COLOR;
				break;
			case SUBFLOW.ARTISTRY:
				this.steps = [...ARTISTRY_STEPS];
				subflow = SUBFLOW.ARTISTRY;
				break;

			default:
				throw new Error('Unknown subflow', section);
		}

		results[subflow] = null;
		this.setState(
			{
				step: this.steps[0],
				stepIndex: -1,
				section: subflow,
				answers: [],
				results
			},
			() => {
				this.nextStep({});
			}
		);
	};
	//#endregion

	//#region Data integration
	fetchQuestions = async (sectionStepHandler) => {
		let response = [];
		this.setState({ isLoading: true });

		switch (sectionStepHandler) {
			case STEP_HANDLERS.COLOR_GROUPS:
				response = await quizApi.fetchQuestions(SECTION_API.COLOR_GROUPS);
				return response;
			case STEP_HANDLERS.COLOR_ELEMENT:
				response = await quizApi.fetchQuestions(
					SECTION_API.COLOR_ELEMENT,
					this.colorGroupResult
				);
				return response;
			case STEP_HANDLERS.ARTISTRY:
				response = await quizApi.fetchQuestions(SECTION_API.ARTISTRY);
				return response;
			default:
				return Promise.resolve([]);
		}
	};

	handleStepResponses = async (answers) => {
		this.setState({ isLoading: true });
		const { anonUser } = this.state;

		switch (this.state.step.handle) {
			case STEP_HANDLERS.STYLE:
				await this.handleStyleQuestions(answers);
				return;
			case STEP_HANDLERS.COLOR_GROUPS:
				this.colorGroupResult = await quizApi.sendResponses(
					SECTION_API.COLOR_GROUPS,
					answers,
					anonUser
				);
				return;
			case STEP_HANDLERS.COLOR_ELEMENT:
				await this.saveResults(SECTION_API.COLOR_ELEMENT, answers);
				return;
			case STEP_HANDLERS.ARTISTRY:
				await this.saveResults(SECTION_API.ARTISTRY, answers);
				return;
			default:
				return;
		}
	};

	saveResults = async (section, answers) => {
		this.colorGroupResult = null;

		const { setQuizProgress } = this.props;
		const { anonUser } = this.state;
		const results = await quizApi.sendResponses(section, answers, anonUser);
		setQuizProgress(results);

		// When the quiz results change and they are completed, save them as filters.
		if (results?.style && results?.color && results?.artistry) {
			localStorageSave(LOCALSTORAGE_KEYS.BLOG_FILTER, {
				style: [results?.style],
				color: [results?.color],
				artistry: [results?.artistry]
			});

			this.resetQuizHasStarted();
		}

		this.setSubflowState(results);
		this.nextScreen();
	};

	changeQuizResult = async (data) => {
		const { user, setQuizProgress } = this.props;
		const anonQuizUser = localStorageGet(LOCALSTORAGE_KEYS.ANON_QUIZ);
		let result = null;

		try {
			if (user) {
				result = await quizApi.changeQuizResultsLoggedUser(data);
			} else if (anonQuizUser) {
				result = await quizApi.changeQuizResultsAnonUser(anonQuizUser, data);
			} else {
				add('Whoops, something went wrong', levels.ERROR);
				return;
			}
		} catch (error) {
			add('Whoops, something went wrong', levels.ERROR);
			return;
		}

		setQuizProgress(result);

		this.setState((prevState, props) => ({
			...prevState,
			results: Object.keys(result).length === 0 ? null : result
		}));
	};
	//#endregion

	render() {
		return (
			<Context.Provider
				value={{
					state: this.state,
					actions: {
						nextScreen: this.nextScreen,
						nextStep: this.nextStep,
						startSubflow: this.startSubflow,
						toggleRetakeDialog: this.toggleRetakeDialog,
						changeQuizResult: this.changeQuizResult,
						handleStyleQuestions: this.handleStyleQuestions,
						prepareRestStyleQuestions: this.prepareRestStyleQuestions,
						handleStyleSectionPreviousQuestions:
							this.handleStyleSectionPreviousQuestions,
						setQuizHasStarted: this.setQuizHasStarted,
						resetQuizHasStarted: this.resetQuizHasStarted
					}
				}}
			>
				{this.props.children}
			</Context.Provider>
		);
	}
}

Provider.propTypes = {
	user: PropTypes.object,
	setQuizProgress: PropTypes.func,
	history: PropTypes.object.isRequired,
	match: PropTypes.object.isRequired
};

export default withRouter(Provider);
export const Consumer = Context.Consumer;
export const QuizContext = Context;
