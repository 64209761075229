import { CONTENT_TYPE } from 'components/LearnManagement/utils';
import API from 'utils/apiConfig';

const fetchArticles = async () => {
  const response = await API.get(`/api/learn/`);
  return response.data;
};

const fetchArticlesTree = async () => {
  const response = await API.get(`/api/learn/tree/`);
  // NOTE: Temporary solution until different types of articles are implemented.
  return response.data?.artistry;
};

const fetchArticle = async articleId => {
  const response = await API.get(`/api/learn/${articleId}/`);
  return response.data;
};

const fetchArticleBySlug = async (slug, styleId, colorId, artistryId) => {
  let url = `/api/learn/${slug}/`;
  let queryParams = '';

  if (styleId !== null) {
    queryParams = `${queryParams}style_id=${styleId}&`;
  }

  if (artistryId !== null) {
    queryParams = `${queryParams}artistry_id=${artistryId}&`;
  }

  if (colorId !== null) {
    queryParams = `${queryParams}color_id=${colorId}`;
  }

  if (queryParams !== '') {
    url = `${url}?${queryParams}`;
  }

  const response = await API.get(url);
  return response.data;
};

const addArticle = async parent => {
  const data = { parent };
  const response = await API.post(`/api/learn/create/`, data);
  return response.data;
};

const moveArticle = async (uuid, target, position) => {
  const data = { target, position };
  const response = await API.patch(`/api/learn/${uuid}/move/`, data);
  // NOTE: Temporary solution until different types of articles are implemented.
  return response.data?.artistry;
};

const changeArticlePublishedStatus = async (uuid, isPublished) => {
  const response = await API.patch(`/api/learn/${uuid}/change-published-status/`, { is_published: isPublished });
  return response.data?.artistry;
}

const deleteArticle = async articleId => {
  const response = await API.delete(`/api/learn/${articleId}/`);
  return response.data;
};

const addField = async articleId => {
  const response = await API.post(`/api/learn/${articleId}/fields/`);
  return response.data;
};

const deleteField = async (articleId, fieldId) => {
  const response = await API.delete(`/api/learn/${articleId}/fields/${fieldId}/`);
  return response.data;
};

const cloneField = async (articleId, fieldId) => {
  const response = await API.post(`/api/learn/${articleId}/fields/${fieldId}/clone/`);
  return response.data;
};

const convertField = async (articleId, fieldId, type) => {
  const data = {
    type
  };

  const response = await API.patch(`/api/learn/${articleId}/fields/${fieldId}/convert/`, data);
  return response.data;
};

const saveField = async (articleUuid, fieldId, contentId, attributeId, contentType, content, image, colorHex) => {
  
  const formData = new FormData();
  formData.append('attribute_id', attributeId);
  formData.append('content_type', contentType);

  if (contentId) {
    formData.append('content_id', contentId);
  }

  if (contentType === CONTENT_TYPE.IMAGE) {
		formData.append('image', image || null);
	} else if (contentType === CONTENT_TYPE.COLOR_SWATCH) {
		formData.append('content', colorHex || null);
	} else {
		formData.append('content', content || null);
	}

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  const response = await API.patch(`/api/learn/${articleUuid}/fields/${fieldId}/`, formData, config);
  return response.data;
};

const saveArticle = async (articleUuid, title, template) => {
  const data = {
    title, template,
  };

  const response = await API.patch(`/api/learn/${articleUuid}/`, data);
  return response.data;
};

const deleteAttribute = async (fieldId, attributeId) => {
  const response = await API.delete(`/api/learn/fields/${fieldId}/attributes/${attributeId}/`);
  return response.data;
};

const addArticleImage = async (articleUuid, image) => {
	if (!image) return;

	const formData = new FormData();
	formData.append(`image`, image);

	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	};

	const response = await API.post(
		`/api/learn/${articleUuid}/article-images/`,
		formData,
		config
	);

	return response.data;
};

const getArticleImages = async (articleUuid, image) => {
	const response = await API.get(`/api/learn/${articleUuid}/article-images/`);

	return response.data;
};


const deleteArticleImage = async (articleUuid, imageUuid) => {
	const response = await API.delete(
		`/api/learn/${articleUuid}/article-images/${imageUuid}/`
	);

	return response.data;
};

export default {
	addField,
	addArticle,
	moveArticle,
	deleteArticle,
	deleteAttribute,
	cloneField,
	convertField,
	deleteField,
	fetchArticle,
	fetchArticleBySlug,
	fetchArticles,
	fetchArticlesTree,
	saveArticle,
	saveField,
	changeArticlePublishedStatus,
	addArticleImage,
  getArticleImages,
  deleteArticleImage,
};
