import styled from "styled-components";
import { Link } from "react-router-dom";

export const Default = styled.button.attrs((props) => ({
  ...(props.aslink && { as: Link }),
  }))`
  ${(props) => `
    color: ${props.theme.colors[props.color] || props.color || "#fff"};
    background: ${
      props.theme.colors[props.background] || props.background || "#000"
    };
    font-family: ${props.theme.fonts.black};
    ${
      props.shadow
        ? `
      box-shadow: ${props.theme.boxShadow.light.primary};
    `
        : `
      box-shadow: unset;
    `
    }
  `}
  width: ${(props) => props.block ? "100%" : "auto"};
  text-shadow: unset;
  padding: 12px 15px;
  user-select: none;
  margin-bottom: 0;
  font-weight: normal;
  text-align: center;
  white-space: nowrap;
  vertical-align: middle;
  touch-action: manipulation;
  cursor: pointer;
  font-size: 16px;
  text-decoration: none;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  border: 0;
  border-radius: 0;
  :hover {
    color: ${(props) => props.color || "#fff"};
    text-decoration: none;
  }
  :focus,
  :active {
    outline: none;
  }
  :disabled {
    opacity: 0.75;
    cursor: not-allowed;
  }
`;

export const Hollow = styled(Default)`
  background: #fff;
  border: 1px solid #000;
  color: #000;
  :hover {
    color: ${(props) => props.color || "#000"};
    text-decoration: none;
  }
`;

export const Styled = styled(Default)`
  :after,
  :before {
    content: "";
    position: absolute;
    background-image: url(/images/rebranding/ButtonArrowLeft.png);
    background-repeat: no-repeat;
    max-width: 40px;
    width: 100%;
    height: 45px;
    top: 50%;
    background-size: contain;
  }
  :before {
    transform: translate(50%, -50%) rotate(180deg);
    right: -20px;
  }
  :after {
    transform: translate(-50%, -50%);
    left: -20px;
  }
`;
