import React from 'react';

import { Consumer } from './RootContext';

export const withRootContext = (Component) => {
  const withRootContext = (props) => (
    <Consumer>
      {context => <Component {...props} context={context} />}
    </Consumer>
  );

  withRootContext.displayName = `withRootContext(${Component.displayName || Component.name}`;

  return withRootContext;
}
