import decode from 'jwt-decode';

import { attachTokenToHeaders, clearTokens } from './apiConfig';
import { TOKEN_KEY } from './constants';
import { LOCALSTORAGE_KEYS } from './constants';

export function getProfile() {
  const decoded = decode(this.getToken());

  console.info('decoded', decoded);
  return decoded;
}

export function getToken() {
  let token = null;
  try {
    token = JSON.parse(localStorage.getItem(TOKEN_KEY));
  } catch (err) {
    console.error('Token parse error', err);
  }
  return token;
}

export function isAccessTokenExpired(token) {
  if (!token || !token.access) {
    return true;
  }

  return isTokenExpired(token.access);
}

export function isRefreshTokenExpired(token) {
  if (!token || !token.refresh) {
    return true;
  }

  return isTokenExpired(token.refresh);
}

export function isTokenExpired(token) {
  try {
    const decoded = decode(token);
    const validToken = decoded.exp < Date.now() / 1000;

    return validToken;
  } catch (err) {
    console.error('Token validation error', err.response);
    return true;
  }
}

export function loggedIn() {
  const token = getToken();

  return !!token && !isAccessTokenExpired(token);
}

export function logout() {
  clearTokens();
  localStorage.removeItem(TOKEN_KEY);
  
  const keysToKeep = [
		LOCALSTORAGE_KEYS.LAST_NOTIFICATIONS_OPENED,
		LOCALSTORAGE_KEYS.PRIVACY_POLICY
	];

  Object.keys(LOCALSTORAGE_KEYS).forEach((key) => {
    if (!keysToKeep.includes(key)) {
			localStorage.removeItem(TOKEN_KEY);
		}
  });
}

export function prepareTokensForUse(token) {
  localStorage.setItem(TOKEN_KEY, JSON.stringify(token));
  attachTokenToHeaders();
}
