import React from "react";
import PropTypes from 'prop-types';
import {
  Wrapper,
  Content,
  Placeholder,
  Svg,
  ActiveCircle,
  SvgPath
} from './CircleCheckbox.styled';

const CircleCheckbox = ({
    color,
    active,
    checkColor,
    checked,
    children,
    ...props
  }) => {
  return (
    <Wrapper
      {...props}
    >
      <Placeholder
        src="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAQAAAC1HAwCAAAAC0lEQVR42mNkYAAAAAYAAjCB0C8AAAAASUVORK5CYII="
      />
      <Content>
        {children}
      </Content>
      <Svg viewBox="2 2 97 97">
        <g>
          {!checked && <ActiveCircle
            visible={active}
            color={color}
            cx="50.4"
            cy="49.8"
            r="45"
          />
          }
          {checked && (
            <>
              <SvgPath
                color={color}
                d="M 50.398 94.818 C 85.037 94.818 106.686 57.32 89.366 27.321 C 72.047 -2.677 28.747 -2.677 11.427 27.321 C 0.772 45.777 4.524 69.176 20.416 83.375"
                transform="matrix(-0.898794, -0.438371, 0.438371, -0.898794, 73.915418, 116.705214)"/>
              <SvgPath
                color={checkColor || color}
                d="M 72.917 17.851 L 78.852 24.279 L 90.47 12.659"
              />
            </>
          )}
        </g>
      </Svg>
    </Wrapper>
  );
};

CircleCheckbox.defaultProps = {
  color: '#000',
};

CircleCheckbox.propTypes = {
  color: PropTypes.string,
  checkColor: PropTypes.string,
  active: PropTypes.bool,
  checked: PropTypes.bool,
};
export default CircleCheckbox;
