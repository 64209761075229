import { PropTypes } from 'prop-types';
import React, { Component } from 'react';
import { Dropdown, MenuItem } from 'react-bootstrap';
import { NavLink, withRouter } from 'react-router-dom';
import styled from 'styled-components';
import classNames from 'classnames';

import CustomToggle from 'components/CustomToggle/CustomToggle';
import { Consumer } from 'context/RootContext';
import { withQuizContext } from 'context/withQuizContext';
import { get as localStorageGet } from 'utils/localstorageService';
import ResumeIcon from 'components/ResumeIcon/ResumeIcon';

const ResumeQuizWrapper = styled.div`
	display: flex;
	align-items: center;
	gap: 5px;
	color: ${({ theme }) => theme.colors.link};
`;

class NavContent extends Component {
	static propTypes = {
		history: PropTypes.object.isRequired,
		keyProp: PropTypes.string,
		location: PropTypes.object.isRequired
	};

	handleLogoutClick = (handleLogout) => {
		const { history } = this.props;

		handleLogout();
		history.push('/login');
	};

	render() {
		const { history } = this.props;

		const settingsForUnsigned = () => (
			<div className="flex-center">
				<NavLink to="/login" className={`navigation-bar-link`}>
					Sign In
				</NavLink>
				<img src="/images/rebranding/verticalSeparator.png" alt="Separator" />
				<NavLink to="/register" className={`navigation-bar-link`}>
					Register
				</NavLink>
			</div>
		);

		const settingForSigned = (handleLogout, user) => {
			const dashboardClass = classNames({
				hidden: !user.is_admin
			});

			return (
				<Dropdown id="dropdown-custom-1" pullRight>
					<CustomToggle bsRole="toggle">
						<div className="navigation-bar-link">
							<img
								className="navigation-bar-link-image"
								src={
									user.profile.picture ||
									'/images/rebranding/placeholder_image.png'
								}
								alt="Profile"
							/>
							{user.first_name || 'Account'}
						</div>
					</CustomToggle>
					<Dropdown.Menu className="dropdown-settings">
						<MenuItem
							eventKey="1"
							className={dashboardClass}
							onClick={() => history.push('/dashboard/users')}
						>
							<div className="menu-item">Dashboard</div>
						</MenuItem>
						<MenuItem eventKey="2" onClick={() => history.push('/settings')}>
							<div className="menu-item">Settings</div>
						</MenuItem>
						<MenuItem
							eventKey="3"
							onClick={this.handleLogoutClick.bind(null, handleLogout)}
						>
							<div className="menu-item">Sign out</div>
						</MenuItem>
					</Dropdown.Menu>
				</Dropdown>
			);
		};

		return (
			<Consumer>
				{(context) => {
					const { handleLogout } = context.actions;
					const { user, isQuizCompleted } = context.state;

					const redirectLink = isQuizCompleted ? '/persona' : '/quiz';
					const navText = isQuizCompleted ? 'Persona' : 'Quiz';

					const isQuizInProgress = localStorageGet('hasStartedQuiz');

					return (
						<div className="navigation-bar">
							<div className="flex-center">
								<NavLink exact to="/" className="navigation-bar-link">
									Home
								</NavLink>
								<NavLink to="/about" className="navigation-bar-link">
									About
								</NavLink>
								<NavLink to="/learn" className="navigation-bar-link">
									Learn
								</NavLink>
								<NavLink to="/discover" className="navigation-bar-link">
									Discover
								</NavLink>
								<NavLink to="/shop" className="navigation-bar-link">
									Shop
								</NavLink>
								<NavLink to="/contact-us" className="navigation-bar-link">
									Contact us
								</NavLink>
								<NavLink to={redirectLink} className="navigation-bar-link">
									{isQuizInProgress ? (
										<ResumeQuizWrapper>
											{navText}
											<ResumeIcon />
										</ResumeQuizWrapper>
									) : (
										navText
									)}
								</NavLink>
							</div>
							<ul className="settings-list">
								<li>
									{user
										? settingForSigned(handleLogout, user)
										: settingsForUnsigned()}
								</li>
							</ul>
						</div>
					);
				}}
			</Consumer>
		);
	}
}

export default withRouter(withQuizContext(NavContent));
