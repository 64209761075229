import React, { Component, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { createGlobalStyle } from 'styled-components';

import CustomBrowserRouter from 'utils/customHistory';
import ErrorBoundary from 'components/ErrorBoundary/ErrorBoundary';

import RootRoutes from 'RootRoutes';
import { Consumer, Provider } from 'context/RootContext';
import { Notify } from 'components/Notification/Notifications';
import Spinner from 'components/Spinner/Spinner';
import { ModalProvider } from 'styled-react-modal';
import GlobalStyle from '../../styles/styled/global-styles';
import './Main.css';
import './App.css';
import OgHelmet from '../OgHelmet/OgHelmet';

import { theme, ThemeProvider } from '../../styles/styled/styled-components';
import StickyPopup from 'components/StickyConsentPopup/StickyPopup';
import NewsletterPopup from 'components/NewsletterPopup/NewsletterPopup';
import Header from 'layout/Header/Header';

const LocationTracker = () => {
	const { pathname, search } = useLocation();
	useEffect(() => {
		if (
			typeof window.gtag === 'function' &&
			process.env.NODE_ENV === 'production' &&
			!pathname.startsWith('/dashboard')
		) {
			window.gtag('event', 'page_view', {
				page_title: window.document.title,
				page_location: window.location.href,
				page_path: window.location.pathname,
				send_to: 'UA-178771324-1'
			});
		}
	}, [pathname, search]);

	return null;
};

const RemoveScrollCss = createGlobalStyle`

  @media only screen and (max-width: 600px) {
		html {
			overflow-x: hidden;
		}
	}
`;

const RootAppComponent = (props) => {
	const { hasAcceptedPrivacyPolicy } = props;
	return (
		<CustomBrowserRouter>
			<ErrorBoundary>
				<LocationTracker />
				<Header />
				<RemoveScrollCss />
				<RootRoutes hasAcceptedPrivacyPolicy={hasAcceptedPrivacyPolicy} />
				{!hasAcceptedPrivacyPolicy && <StickyPopup />}
				{hasAcceptedPrivacyPolicy && <NewsletterPopup />}
			</ErrorBoundary>
		</CustomBrowserRouter>
	);
};

class App extends Component {
	render() {
		const style = {
			style: {
				NotificationItem: {
					// Override the notification item
					DefaultStyle: {
						// Applied to every notification, regardless of the notification level
						fontSize: 20,
						fontWeight: 'bold'
					}
				}
			}
		};

		return (
			<Provider>
				<ThemeProvider theme={theme.default}>
					<GlobalStyle />
					<ModalProvider>
						<OgHelmet
							description="We know everything about art and everything about dress"
							image="https://theartofdress.com/images/rebranding/logo-1.png"
							author="@theartofdress"
						>
							<meta name="twitter:site" content="@theartofdress" />
							<meta name="fb:app_id" content="1238781937189" />
						</OgHelmet>
						<div className="root">
							<Consumer>
								{(context) => {
									const { appInitialized, hasAcceptedPrivacyPolicy } =
										context.state;
									return appInitialized ? (
										<RootAppComponent
											hasAcceptedPrivacyPolicy={hasAcceptedPrivacyPolicy}
										/>
									) : (
										<Spinner />
									);
								}}
							</Consumer>
							<Notify {...style} />
						</div>
					</ModalProvider>
				</ThemeProvider>
			</Provider>
		);
	}
}

export default App;
