import React, { Component } from 'react';
import { Button, Image, Panel } from 'react-bootstrap';

const footerStyle = {
  display: 'flex',
  'justifyContent': 'center'
};

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = {
      hasError: false
    };
  }

  componentDidCatch(error, info) {
    this.setState({
      hasError: true
    });
    console.error('Component tree error:', error);
    console.error('Info:', info);
  }

  render() {
    if (this.state.hasError) {
      return (
        <Panel bsStyle="warning">
          <Panel.Heading>
            <Panel.Title componentClass="h1">Something went wrong!</Panel.Title>
          </Panel.Heading>
          <Panel.Body>
            <Image src="/images/oops.png" responsive />
          </Panel.Body>
          <Panel.Footer style={footerStyle}>
            <Button bsStyle="primary" className="center-content" href="/">Go to Home page</Button>
          </Panel.Footer>
        </Panel>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
