import React, { Suspense } from 'react';
import { Switch } from 'react-router-dom';

import { routesArr } from 'routes.const';

const RootRoutes = () => {
	return (
		<Suspense fallback={<div>Loading...</div>}>
			<Switch>
				{routesArr.map(({ route: RouteComponent, ...rest }, i) => (
					<RouteComponent key={`route-${i}`} {...rest} />
				))}
			</Switch>
		</Suspense>
	);
};

export default RootRoutes;
