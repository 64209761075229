import StyledModalBase from 'styled-react-modal';
import { FaAngleLeft } from 'react-icons/fa';
import {
	H3,
	Close as CloseButton,
	Buttons
} from '../../../styles/styled/components';
import styled from 'styled-components';

export const StyledModal = StyledModalBase.styled`
  position: relative;
  min-width: 20%;
  max-width: ${(props) => props.maxWidth || '75%'};
  width: 100%;
  padding: 35px 50px;
  display: flex;
  flex-direction: column;
  ${(props) =>
		props.scrollable &&
		`
      max-height: 80vh;
      overflow-y: auto;
    `}
  background-color: ${(props) => props.theme.colors.background};
  box-shadow: ${(props) => props.theme.boxShadow.strong.primary};
  @media ${(props) => props.theme.device.mtablet} {
    ${(props) =>
			props.filterModalMobile &&
			`
      max-width: 100%;
      height: 100vh;
      overflow-y: auto;
      padding-top: 70px;
    `}
  };
`;
export const Close = styled(CloseButton)`
	${(props) =>
		props.absolute &&
		`
  position: absolute;
  right: 15px;
  top: 15px;
  `}
`;
export const LeftChevron = styled(FaAngleLeft)`
	font-size: 1.5em;
	margin-right: 20px;
`;
export const Header = styled.div`
	margin-bottom: 35px;
	display: flex;
	flex-direction: row;
	justify-content: space-between;
	align-items: center;
	@media ${(props) => props.theme.device.mtablet} {
		${(props) =>
			props.filterModalMobile &&
			`
      flex-direction: row-reverse;
      justify-content: flex-end;
    `}
	}
`;
export const Title = styled(H3).attrs({
	as: 'div'
})`
	margin-right: 20px;
`;

export const Content = styled.div`
	margin-bottom: 35px;
	font-size: 20px;
	&:last-child {
		margin-bottom: 0;
	}
`;

export const Footer = styled.div`
	display: flex;
	justify-content: ${(props) => props.buttonsPosition || 'flex-end'};
`;

export const Button = styled(Buttons.Default)`
	display: block;
	flex-grow: 0.1;
	opacity: ${(props) => (props.canConfirm === false ? '0.5' : '1')};
	cursor: ${(props) =>
		props.canConfirm === false || props.disabled === true
			? 'not-allowed'
			: 'pointer'};
	${Footer} & {
		margin-left: 10px;
		&:first-child {
			margin-left: 0;
		}
	}
`;
