import { useEffect, useState } from 'react';

import { profileApi } from 'api/index';
import { save as localStorageSave } from 'utils/localstorageService';
import { LOCALSTORAGE_KEYS } from 'utils/constants';

import { add, levels } from 'components/Notification/Notifications';

const useSubscribeNewsletter = () => {
	const [isLoading, setLoading] = useState(false);
	const [isError, setIsError] = useState(false);
	const [error, setError] = useState(undefined);

	const subscribeAsync = async (isUser, values) => {
		try {
			setLoading(true);
			const data = {
				email: values?.email,
				first_name: values?.firstName || '',
				last_name: values?.lastName || ''
			};

			if (isUser) {
				await profileApi.subscribeUserToMailchimp();
			} else {
				await profileApi.subscribeGuestUserToMailchimp(data);
			}

			localStorageSave(LOCALSTORAGE_KEYS.NEWSLETTER_SUBSCRIBED, {
				closedAt: null,
				newsletterSubscription: true
			});
		} catch (error) {
			setError(error);
			setIsError(true);
		} finally {
			setLoading(false);
		}
	};

	useEffect(() => {
		if (!isError) return;
		add('Something went wrong', levels.ERROR);
	}, [isError]);

	return { subscribeAsync, isLoading, isError, error };
};

export default useSubscribeNewsletter;
