import { PropTypes } from 'prop-types';
import React from 'react';

export default class CustomToggle extends React.Component {
  handleClick = e => {
    e.preventDefault();

    this.props.onClick(e);
  }

  render() {
    const childrenWithProps = React.cloneElement(this.props.children, { onClick: this.handleClick });

    return (
      <React.Fragment>
        {childrenWithProps}
      </React.Fragment>
    );
  }
}

CustomToggle.propTypes = {
  onClick: PropTypes.func,
  enabled: PropTypes.bool
};
