import PropTypes from 'prop-types';

import { IMAGE_NOT_FOUND } from 'utils/constants';

const ImageDefault = ({children, src}) => {
  const onError = e => {
    if (e.target.src.includes(IMAGE_NOT_FOUND)) {
      return;
    }
    e.target.src = IMAGE_NOT_FOUND;
  };

  const safeSrc = src || IMAGE_NOT_FOUND;

  return children(safeSrc, onError);
};

ImageDefault.propTypes = {
  src: PropTypes.string,
};

export default ImageDefault;
