import styled from 'styled-components';

export const QuizChoice = styled.div`
	&:hover {
		cursor: pointer;
		outline: 4px solid #00000052;
	}

	background: ${(props) => (props.isStyleSection ? 'transparent' : '#fff')};
	box-shadow: ${(props) =>
		props.isStyleSection ? 'unset' : '0 10px 50px 0 rgba(0, 0, 0, 0.1)'};
	display: inline-flex;
	justify-content: center;
	align-items: center;
	padding: ${(props) => (props.colorOption ? '35px' : 'unset')};

	@media ${(props) => props.theme.device.mtablet} {
		padding: ${(props) => (props.colorOption ? '25px' : 'unset')};
	}

	@media ${(props) => props.theme.device.mlaptop} {
		padding: ${(props) => (props.colorOption ? '30px' : 'unset')};
	}

	${(props) => `
    @media ${props.theme.device.mlaptopL} {
      margin: 5px 0px;
    }
  `}

	${(props) =>
		props.isSelected &&
		`
    outline: 4px solid #000;
  `}
`;

export const QuestionImage = styled.img`
	max-width: 267px;
	@media ${(props) => props.theme.device.laptopLUp} {
		max-width: 267px;
	}
	@media ${(props) => props.theme.device.mDesktop} {
		max-width: 220px;
	}
	@media ${(props) => props.theme.device.tabletOnly} {
		max-width: 170px;
	}
	@media ${(props) => props.theme.device.mtablet} {
		max-width: 150px;
		height: ${(props) => (props.isStyleSection ? '200px' : 'auto')};
	}
`;

export const ColorOptions = styled.div`
	height: 190px;
	width: 50px;
	@media ${(props) => props.theme.device.mlaptop} {
		width: 50px;
		height: 130px;
	}

	@media ${(props) => props.theme.device.mtablet} {
		width: 20px;
		height: 100px;
	}
`;

export const ColorWrapper = styled.div`
	padding: 20px;
	background: #fff;
	box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
	padding: 37.8px 42px 37.8px 43px;
	display: flex;
	align-items: center;
	justify-content: center;
`;

export const OptionsWrapper = styled.div`
	display: flex;
	grid-template-columns: repeat(4, 1fr);
	align-items: center;
	margin-bottom: 1rem;
	justify-content: ${(props) =>
		props.question > 3 ? 'space-between' : 'unset'};
	width: ${(props) => (props.question > 3 ? '100%' : 'unset')};
	grid-column-gap: ${(props) => (props.question <= 3 ? '20px' : 'unset')};

	@media ${(props) => props.theme.device.mlaptop} {
		display: grid;
		grid-column-gap: 10px;
		column-gap: 10px;
		justify-content: unset;
		width: unset;
	}

	@media ${(props) => props.theme.device.mtablet} {
		display: grid;
		grid-template-columns: repeat(2, 1fr);
		grid-column-gap: 10px;
		column-gap: 10px;
	}
`;
