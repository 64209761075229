import React from 'react';
import styled from 'styled-components';
import PropTypes from 'prop-types';

const Wrapper = styled.div`
	a {
		text-decoration: underline;
		font-weight: bold;
	}
`;

const HtmlText = ({ text = '', Element = 'div', ...rest }) => {
	return (
		<Wrapper {...rest}>
			<Element dangerouslySetInnerHTML={{ __html: text }} />
		</Wrapper>
	);
};

HtmlText.propTypes = {
	text: PropTypes.string.isRequired,
	Element: PropTypes.string
};

export default HtmlText;
