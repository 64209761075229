import React, { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import styled from 'styled-components';

import { Alert } from 'react-bootstrap';
import { Close as CloseButton } from 'styles/styled/components';
import { notificationApi } from 'api';
import * as localstorageService from 'utils/localstorageService';
import { LOCALSTORAGE_KEYS } from 'utils/constants';
import HtmlText from 'components/HtmlText/HtmlText';
import { useCheckMobileScreen } from 'utils/hooks';

const CustomizedAlert = styled(Alert)`
	background-color: #3a7eb2;
	border-radius: 0%;
	background-image: none;
	color: white;
	position: sticky;
	display: flex;
	width: 100%;
	@media ${(props) => props.theme.device.mlaptop} {
		width: auto;
	}
	flex-direction: row;
	justify-content: center;
	margin: 0;
	img {
		max-height: 30px;
		max-width: 30px;
	}
`;

const CloseWrapper = styled.div`
	flex: 1;
	display: flex;
	align-self: center;
`;

const NotificationWrapper = styled.div`
	flex: 50;
	display: flex;
	justify-content: center;

	/* adjust white color for the link */
	& > div > h4 > p > a {
		color: white;
	}
`;

const HeaderNotification = () => {
	const [activeNotifications, setActiveNotifications] = useState([]);
	const isMobile = useCheckMobileScreen();
	const { pathname } = useLocation();

	useEffect(() => {
		const getActiveNotifications = async () => {
			const currentActiveNotifications =
				await notificationApi.getActiveNotifications();

			// If there are no published notifications, clean the notifications track from the local storage.
			if (currentActiveNotifications.length === 0) {
				localstorageService.remove(LOCALSTORAGE_KEYS.LAST_NOTIFICATIONS_OPENED);
				setActiveNotifications([]);
				return;
			}

			const activeNotifications = !isMobile
				? currentActiveNotifications
				: currentActiveNotifications.filter(
						(item) => item.show_on_mobile === true
				  );
			const lastReadNotifications = localstorageService.get(
				LOCALSTORAGE_KEYS.LAST_NOTIFICATIONS_OPENED
			);

			// If there are no notifications from the local storage,
			// all fetched notifications should be considered as the active and displayed on the UI.
			if (!lastReadNotifications) {
				setActiveNotifications(activeNotifications);
				return;
			}

			// Compare the notifications from the local storage with the ones obtained from the API.
			const notificationsToShow = activeNotifications.filter((item) => {
				const localStorageNotification = lastReadNotifications[item.id];
				if (!localStorageNotification) return true;
				return Date.parse(item.updated_at) > localStorageNotification.closedAt;
			});

			setActiveNotifications(notificationsToShow);
		};
		getActiveNotifications();
	}, [pathname, isMobile]);

	const closeNotification = (notification) => {
		const lastReadNotifications = localstorageService.get(
			LOCALSTORAGE_KEYS.LAST_NOTIFICATIONS_OPENED
		);

		let notificationsObject = {
			[notification.id]: {
				closedAt: Date.now()
			}
		};
		if (!!lastReadNotifications) {
			notificationsObject = {
				...lastReadNotifications,
				[notification.id]: {
					closedAt: Date.now()
				}
			};
		}
		localstorageService.save(
			LOCALSTORAGE_KEYS.LAST_NOTIFICATIONS_OPENED,
			notificationsObject
		);

		setActiveNotifications(
			activeNotifications.filter((item) => notification.id !== item.id)
		);
	};

	return (
		<>
			{activeNotifications.map((notification) => (
				<CustomizedAlert key={notification.id}>
					<NotificationWrapper>
						<HtmlText text={notification.text} Element="h4" />
					</NotificationWrapper>
					<CloseWrapper>
						<CloseButton
							signColor="white"
							size="10"
							onClick={() => closeNotification(notification)}
						/>
					</CloseWrapper>
				</CustomizedAlert>
			))}
		</>
	);
};

export default HeaderNotification;
