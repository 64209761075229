import styled from "styled-components";

export default styled.div`
	color: ${(props) => props.color || '#000'};
	width: ${(props) => props.size || 25}px;
	height: ${(props) => props.size || 25}px;
	position: relative;
	cursor: pointer;
	&:focus {
		outline: unset;
	}
	&:before,
	&:after {
		position: absolute;
		left: 43%;
		top: -11.5%;
		content: ' ';
		height: 125%;
		width: 10%;
		background-color: ${(props) => props.signColor || '#333'};
	}
	&:before {
		transform: rotate(45deg);
	}
	&:after {
		transform: rotate(-45deg);
	}
	${(props) => props.theme.noSelect}
`;
