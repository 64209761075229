import API from 'utils/apiConfig';
import * as AuthService from 'utils/authService';
import {get as localStorageGet} from "../utils/localstorageService";
import {LOCALSTORAGE_KEYS} from "../utils/constants";

const login = (username, password) => {
  return auth(username, password)
    .then(getCurrentUser);
};

const googleLogin = (code) => {
  return googleAuth(code)
    .then(getCurrentUser);
};

const facebookLogin = (code) => {
  return facebookAuth(code)
    .then(getCurrentUser);
};

const logout = () => {
  AuthService.logout();
};

const auth = (username, password) => {
  return API.post(`api/auth/token/obtain/`, {
      username,
      password
    })
    .then(res => {
      AuthService.prepareTokensForUse(res.data);
    });
};

const googleAuth = (access_token) => {
  return API.post(`api/auth/token/obtain/oauth/`, {
    access_token,
    anonQuizUser:  localStorageGet(LOCALSTORAGE_KEYS.ANON_QUIZ) || undefined,
    provider: 'google-plus',
  })
    .then(res => {
      AuthService.prepareTokensForUse(res.data);
    });
};

const facebookAuth = (access_token) => {
  return API.post(`api/auth/token/obtain/oauth/`, {
    access_token,
    anonQuizUser:  localStorageGet(LOCALSTORAGE_KEYS.ANON_QUIZ) || undefined,
    provider: 'facebook',
  })
    .then(res => {
      AuthService.prepareTokensForUse(res.data);
    });
};

const echo = (message) => {
  return API.post(
    `api/echo/`,
    { message }
  );
};

const getCurrentUser = () => {
  return API.get(`api/users/`)
    .then(res => res.data);
};

export default {
  auth,
  googleAuth,
  facebookAuth,
  echo,
  getCurrentUser,
  login,
  googleLogin,
  facebookLogin,
  logout
};
