import React, { useState } from 'react';
import classNames from 'classnames';

import {
	Title,
	StyledButton,
	Label,
	Content,
	Form
} from 'components/NewsletterPopup/NewsletterPopup.styled';

import useSubscribeNewsletter from './useSubscribeNewsletter';

const RegisterUser = ({ nextStep, step }) => {
	const [values, setValues] = useState({
		firstName: '',
		lastName: '',
		email: ''
	});
	const { isLoading, subscribeAsync } = useSubscribeNewsletter();

	const handleChange = (e) => {
		setValues({
			...values,
			[e.target.name]: e.target.value
		});
	};

	const handleConfirm = async (e) => {
		e.preventDefault();

		await subscribeAsync(false, values);
		setValues({ firstName: '', lastName: '', email: '' });

		nextStep(step);
	};

	const emailRegex = /\S+@\S+\.\S+/;

	const currentEmail = classNames('form-control', {
		'has-errors': values?.email?.length > 0 && !emailRegex.test(values.email)
	});

	return (
		<Form onSubmit={handleConfirm}>
			<Title>Add your email below</Title>
			<Content>
				<Label>FIRST NAME</Label>
				<input
					id="firstName"
					className="form-control"
					type="text"
					placeholder="First Name"
					disabled={isLoading}
					name="firstName"
					onChange={handleChange}
					defaultValue={values.firstName}
					required
				/>
				<Label>LAST NAME</Label>
				<input
					id="lastName"
					className="form-control"
					type="text"
					placeholder="Last Name"
					disabled={isLoading}
					name="lastName"
					onChange={handleChange}
					defaultValue={values.lastName}
					required
				/>

				<Label>EMAIL</Label>
				<input
					id="email"
					className={currentEmail}
					type="email"
					placeholder="Email"
					disabled={isLoading}
					name="email"
					onChange={handleChange}
					defaultValue={values.email}
					required
				/>
			</Content>
			<div>
				<StyledButton
					disabled={
						!emailRegex.test(values.email) ||
						values.firstName === '' ||
						values.lastName === '' ||
						isLoading
					}
					type="submit"
				>
					Sign me up
				</StyledButton>
			</div>
		</Form>
	);
};

export default RegisterUser;
