import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { createBrowserHistory } from 'history';

import 'bootstrap/dist/css/bootstrap.css';
import 'bootstrap/dist/css/bootstrap-theme.css';
import 'bootstrap-social/bootstrap-social.css';
import 'react-quill/dist/quill.snow.css';
import 'rc-color-picker/assets/index.css';
import 'animate.css';
import 'react-date-range/dist/styles.css'; // main style file
import 'react-date-range/dist/theme/default.css'; // theme css file

import App from './components/App/App';
import * as ApiConfig from 'utils/apiConfig';

// This part is used as a solution for the AWS S3 hosting
const replaceHashPath = () => {
	const history = createBrowserHistory();
	const hash = history.location.hash;
	if (hash) {
		const path = hash.replace(/^#/, '');
		if (path) {
			history.replace(path);
		}
	}
};

ApiConfig.setupInterceptors();

if (process.env.NODE_ENV === 'production') {
	window.dataLayer = window.dataLayer || [];
	// eslint-disable-next-line no-inner-declarations
	window.gtag = function gtag() {
		window.dataLayer.push(arguments);
	};

	window.gtag('js', new Date());

	window.gtag('config', 'UA-178771324-1');
}

replaceHashPath();

// sentry must be initialized before rendering the DOM
Sentry.init({
	dsn: 'https://c28bd06592954383afb00836ebe94e6f@o4503915045126144.ingest.sentry.io/4503915059478528',
	integrations: [new BrowserTracing()],

	// Set tracesSampleRate to 1.0 to capture 100%
	// of transactions for performance monitoring.
	// We recommend adjusting this value in production
	tracesSampleRate: 1.0
});

ReactDOM.render(<App />, document.getElementById('root'));
