import React, { Component } from 'react';
import PropTypes from 'prop-types';

import { withQuizContext } from 'context/withQuizContext';
import { STEP_HANDLERS } from 'utils/constants';
import Question from './Question';

import {
	QuizFrame,
	StepTitle,
	StepTitleTextWrapper,
	QuizFooter,
	ButtonHollow,
	ButtonDefault,
	Subtitle,
	Instruction,
	InstructionText,
	SectionName
} from './Quiz.styled';
import Spinner from 'components/Spinner/Spinner';

class SectionSelection extends Component {
	constructor(props) {
		super(props);

		this.state = {
			answers: [],
			choiceId: ''
		};

		this.prevSelectedOption = null;
	}

	getAnswerByChoiceId = (choiceId) =>
		this.props.quizContext.state.questions.find(
			(question) => question.id === choiceId
		);

	makeChoice = (choiceId) => {
		this.setState({ choiceId });
	};

	handleNextStep = () => {
		const { step } = this.props.quizContext.state;
		this.props.quizContext.actions.setQuizHasStarted();

		if (step.handle === STEP_HANDLERS.STYLE) {
			this.handleNextStyleStep();
		} else {
			this.handleNextStepColorAndArtistry();
		}
	};

	handleNextStepColorAndArtistry = () => {
		const { questions } = this.props.quizContext.state;
		const { nextStep } = this.props.quizContext.actions;
		const answers = [...this.state.answers];

		answers.push(this.state.choiceId);

		if (answers.length === questions.length) {
			this.setState({ answers: [] });
			nextStep({ answers });
			return;
		}

		this.setState({ answers, choiceId: '' });
	};

	handleNextStyleStep = async () => {
		const { prepareRestStyleQuestions, handleStyleQuestions } =
			this.props.quizContext.actions;
		const answers = [...this.state.answers];

		answers.push(this.state.choiceId);

		const questionIndex = answers.length;
		const currentSelectedOption = this.getAnswerByChoiceId(this.state.choiceId);
		const newQuestions = prepareRestStyleQuestions(
			currentSelectedOption,
			questionIndex
		);

		let result = null;

		if (newQuestions.length === 1) {
			result = await handleStyleQuestions(answers);
			if (!result) {
				await handleStyleQuestions([]);
			}
		}

		this.setState({ answers, choiceId: '' });
	};

	handlePreviosStep = () => {
		const { step } = this.props.quizContext.state;
		const { handleStyleSectionPreviousQuestions } =
			this.props.quizContext.actions;
		const answers = [...this.state.answers];

		if (step.handle === STEP_HANDLERS.STYLE) {
			handleStyleSectionPreviousQuestions(answers);
		}

		this.setState((prevState) => {
			prevState.answers.pop(this.state.choiceId);
			return {
				answers: prevState.answers,
				choiceId: ''
			};
		});
	};

	determineQuizQuestion = () => {
		const { step, questions } = this.props.quizContext.state;
		const { answers } = this.state;

		if (step.handle === STEP_HANDLERS.STYLE) {
			return questions;
		} else {
			const ix = answers.length;
			const question = questions[ix];
			return question;
		}
	};

	determineSectionTitle = () => {
		const {
			questions,
			section,
			step,
			currentStyleRound,
			currentStyleStage,
			allStyleChoices
		} = this.props.quizContext.state;
		const { answers } = this.state;

		const questionStartingPoint = answers.length + 1;
		let questionsNumber = questions.length;
		let prefix = '';

		if (step.handle === STEP_HANDLERS.STYLE) {
			// Each question has maximum of 4 options presented to user. Each selected answer is carried to next question.
			// Next question will have up to 4 options created from previously selected option and set of max 3 options.
			questionsNumber = Math.floor((allStyleChoices.length + 1) / 3);

			if ((currentStyleRound, currentStyleStage)) {
				prefix = `Round ${currentStyleRound}, Stage ${currentStyleStage}`;
			}
		}
		const title = `Step ${questionStartingPoint} of ${questionsNumber}`;

		if (prefix) {
			return (
				<>
					<Subtitle>
						<SectionName>{section}</SectionName>: {prefix}, {title}
					</Subtitle>
				</>
			);
		}
		return <span>{title}</span>;
	};

	render() {
		const { section } = this.props.quizContext.state;
		const { answers, choiceId } = this.state;

		const question = this.determineQuizQuestion();

		if (!question || !Array.isArray(question)) return <Spinner />;

		return (
			<QuizFrame>
				<StepTitleTextWrapper>
					<StepTitle>Quiz</StepTitle>
					{this.determineSectionTitle()}
				</StepTitleTextWrapper>
				{question ? (
					<>
						<Question
							question={question}
							makeChoice={this.makeChoice}
							section={section}
							choiceId={choiceId}
						/>
						<Instruction>
							<InstructionText>
								<span>
									Please select an option and click &ldquo;Next&ldquo;
								</span>
							</InstructionText>
						</Instruction>
						<QuizFooter answers={answers}>
							{answers && answers.length > 0 && (
								<ButtonHollow onClick={this.handlePreviosStep}>
									Back
								</ButtonHollow>
							)}
							<ButtonDefault disabled={!choiceId} onClick={this.handleNextStep}>
								Next
							</ButtonDefault>
						</QuizFooter>
					</>
				) : null}
			</QuizFrame>
		);
	}
}

SectionSelection.propTypes = {
	quizContext: PropTypes.shape({
		actions: PropTypes.object.isRequired,
		state: PropTypes.object.isRequired
	}),
	params: PropTypes.object.isRequired
};

export default withQuizContext(SectionSelection);
