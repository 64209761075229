import React from 'react';
import PropTypes from 'prop-types';

import If from 'components/If/If';
import ImageDefault from 'components/ImageDefault/ImageDefault';
import ColorImage from 'components/New/Basic/ColorImage/ColorImage';
import {
	BlockTypeText,
	BlockNameText,
	BlockTypesWrapper,
	ResultImage,
	ResultIconWrapper
} from 'components/Persona/Persona.styled';
import ChangeQuizResult from 'components/Persona/ChangeResult';
import { BLOCK_TYPE } from 'utils/constants';
import './PersonaHeader.css';

const QuizResultsItem = ({
	block,
	blockType,
	persona,
	attributes,
	changeQuizResult
}) => {
	return block ? (
		<BlockTypesWrapper>
			<BlockTypeText>{blockType}</BlockTypeText>
			<BlockNameText>{block.name}</BlockNameText>
			<ResultIconWrapper>
				<If predicate={blockType !== BLOCK_TYPE.COLOR}>
					<ImageDefault src={block.url}>
						{(safeSrc, onError) => (
							<ResultImage src={safeSrc} alt="Style result" onError={onError} />
						)}
					</ImageDefault>
				</If>
				<If predicate={blockType === BLOCK_TYPE.COLOR}>
					<ColorImage color={block.color} />
				</If>
			</ResultIconWrapper>
			{attributes && persona && (
				<ChangeQuizResult
					quizProgress={persona}
					attributes={attributes}
					blockType={blockType}
					changeSelectedItem={changeQuizResult}
				/>
			)}
		</BlockTypesWrapper>
	) : (
		<BlockTypesWrapper>
			<BlockTypeText>{blockType}</BlockTypeText>
			<div className="separator">
				<img
					className="img-responsive"
					src="/images/rebranding/seperator.png"
					alt="Seperator"
				/>
			</div>
		</BlockTypesWrapper>
	);
};

QuizResultsItem.propTypes = {
	block: PropTypes.shape({
		id: PropTypes.number.isRequired,
		name: PropTypes.string.isRequired
	}),
	blockType: PropTypes.oneOf([
		BLOCK_TYPE.COLOR,
		BLOCK_TYPE.ARTISTRY,
		BLOCK_TYPE.STYLE
	]).isRequired,
	changeQuizResult: PropTypes.func.isRequired,
	persona: PropTypes.object.isRequired,
	attributes: PropTypes.object
};

export default QuizResultsItem;
