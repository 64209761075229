import React, { Component } from 'react';
import Notifications from 'react-notification-system';

var notifications = null;

export const levels = {
  ERROR: 'error',
  INFO: 'info',
  SUCCESS: 'success',
  WARNING : 'warning'
};

export function add(message, level, props) {
  const notification = {
    message,
    level,
    ...props
  };

  return notifications
    && notifications.addNotification(notification);
}

export function remove(uid) {
  return notifications
    && notifications.removeNotification(uid);
}

export class Notify extends Component {
  componentDidMount() {
    notifications = this.refs['notifications'];
  }

  componentWillUnmount() {
    notifications = null;
  }

  render() {
    return (
      <Notifications ref="notifications" {...this.props} />
    );
  }
}

// Notify.propsTypes = {
//     level: PropTypes.string,
//     title: PropTypes.string,
//     message: PropTypes.string,
//     position: PropTypes.string,
//     autoDismiss: PropTypes.number,
//     dismissible: PropTypes.bool,
//     action: PropTypes.object,
//     onAdd: PropTypes.func,
//     onRemove: PropTypes.func,
//     uid: PropTypes.oneOfType([
//         React.PropTypes.string,
//         React.PropTypes.number
//     ])
// };
