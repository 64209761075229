import qs from 'qs';
import API from 'utils/apiConfig';

const getUserList = async ({
  page = 1,
  itemsPerPage = 10,
  sortBy = null,
  sortOrder = 'asc',
  search = null,
  isAdmin = null,
  isActive = null,
  isSocialAuth = null,
} = {}) => {
  const query = generateUsersQueryParams({
    page,
    itemsPerPage,
    sortBy,
    sortOrder,
    search,
    isAdmin,
    isActive,
    isSocialAuth,
  });

  const response = await API.get(`/api/users/list/?${qs.stringify(query)}`);
  return await response.data;
};

const toggleAdminParams = async (userId, {isAdmin: is_admin, isActive: is_active}) => {
  const data = { is_admin, is_active };
  const response = await API.patch(`/api/users/${userId}/admin/`, data);

  return await response.data;
};

const generateUsersQueryParams = ({
  page = 1,
  itemsPerPage = 10,
  sortBy = null,
  sortOrder = 'asc',
  search = null,
  isAdmin = null,
  isActive = null,
  isSocialAuth = null,
}) => {
  /*
    page = number
    page_size = number
    is_active = <true, false>
    is_admin = <true, false>
    is_social_auth = <true, false>
    ordering = <id, email, first_name, last_name>
    search = <will search email, first name, and last name with any string>
  */
  const query = {
    page,
    page_size: itemsPerPage,
  };

  if(typeof isActive === 'boolean') {
    query.is_active = String(isActive);
  }

  if(typeof isAdmin === 'boolean') {
    query.is_admin = String(isAdmin);
  }

  if(typeof isSocialAuth === 'boolean') {
    query.is_social_auth = String(isSocialAuth);
  }

  if(sortBy) {
    query.ordering = sortOrder === 'desc' ? `-${sortBy}` : sortBy;
  }

  if(search && typeof search === 'string') {
    query.search = search;
  }

  return query;
};

export default {
  getUserList,
  toggleAdminParams,
  generateUsersQueryParams,
};
