import React from 'react';
import PropTypes from 'prop-types';
import Lottie from 'lottie-react';
import styled from 'styled-components';
import loadingAnimation from './Loading.json';
import classNames from 'classnames';

const Spinner = ({ absolute = false }) => {
	const panelSpinnerClass = classNames({ absolute });
	return (
		<SpinnerWrapper
			className={panelSpinnerClass}
			animationData={loadingAnimation}
		/>
	);
};

const SpinnerWrapper = styled(Lottie)`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 90%;
	&.absolute{
		position: absolute;
		left: 0;
		right: 0;
		height: 50vh;
	}
	svg {
		max-width: 300px !important;
		max-height: 300px !important;
		@media ${(props) => props.theme.device.mtablet} {
			max-width: 250px !important;
			max-height: 250px !important;
		}
	}
`;

Spinner.propTypes = {
	absolute: PropTypes.bool
};

Spinner.defaultProps = {
	absolute: false
};

export default Spinner;
