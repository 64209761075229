import API from 'utils/apiConfig';
import { PAGINATION, BLOG_TYPES } from 'utils/constants';

const getPosts = async (pageNr, pageSize, filter, operator='and', search = null, ordering = null) => {
  if (!pageNr) {
    pageNr = 1;
  }
  if (!pageSize) {
    pageSize = PAGINATION.PAGE_SIZE;
  }

  let url = `api/blogs/posts/?page=${pageNr}&page_size=${pageSize}&persona_operator=${operator}`;

  if (filter) {
    url += `&${filter}`;
  }

  if (search) {
    url += `&search=${search}`;
  }

  if (ordering) {
    url += `&ordering=${ordering}`
  }

  const response = await API.get(url);
  return await response.data;
};

const getPost = async (slug) => {
  let url = `api/blogs/posts/${slug}/`;
  const response = await API.get(url);
  return await response.data;
};

const getPostTypes = async () => {
  let url = `api/blogs/posts/types/`;
  const response = await API.get(url);
  return await response.data;
};

const deletePost = async (slug) => {
  let url = `api/blogs/posts/${slug}/manage/`;
  const response = await API.delete(url);
  return await response.data;
};

const savePost = async (post) => {
  const formData = new FormData();

  formData.append(`title`, post.title);
  formData.append(`description`, post.description);
  formData.append(`text`, post.text);
  formData.append(`published_at`, new Date(post.published_at).toISOString());
  formData.append(`styles`, post.styles.map(s => s.id).join(','));
  formData.append(`colors`, post.colors.map(s => s.id).join(','));
  formData.append(`artistries`, post.artistries.map(s => s.id).join(','));
  formData.append(`post_type`, post.post_type.id);
  formData.append(`videos`, JSON.stringify(post.videos));

  post.pictures.forEach((pic, index) => {
    formData.append(`url_${index}`, pic.id ? pic.url : pic.file);
    formData.append(`order_number_${index}`, pic.order_number);
    formData.append(`post_${index}`, pic.post);
    formData.append(`image_id_${index}`, pic.id || 0);
    formData.append(`desc_${index}`, pic.desc);
  });

  let url = post.slug ? `api/blogs/posts/${post.slug}/manage/` : `api/blogs/posts/create`;
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };
  const response = post.id ? await API.put(url, formData, config) : await API.post(url, formData, config);
  return await response.data;
};


const search = async (query, type, pageNr = 1, pagedUrl) => {
  const pageSize = PAGINATION.PAGE_SIZE;

  if (+type === BLOG_TYPES.ALL) {
    type = '';
  }

  let url = pagedUrl || `api/blogs/search/${query}/?page=${pageNr}&page_size=${pageSize}&post_type=${type}`;
  const response = await API.get(url);

  return await response.data;
};

const getByUrl = async url => {
  const response = await API.get(url);

  return await response.data;
};

export default {
  deletePost,
  getByUrl,
  getPosts,
  getPostTypes,
  getPost,
  savePost,
  search,
};
