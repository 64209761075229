import API from 'utils/apiConfig';

const fetchArticles = async () => {
  const response = await API.get(`/api/shop/`);
  return response.data;
};

const fetchStaticArticle = async section => {
  const response = await API.get(`/api/shop/static/${section}/`);
  return response.data;
};

const fetchColorArticle = async colorId => {
  const response = await API.get(`/api/shop/colour/${colorId}/`);
  return response.data;
};

const fetchStyleArticle = async styleId => {
  const response = await API.get(`/api/shop/style/${styleId}/`);
  return response.data;
};

const updateStaticArticle = async (section, image, link, price, active) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  const formData = new FormData();
  formData.append(`image`, image.file ? image.file : image.url);
  formData.append(`link`, link);
  formData.append(`price`, price);
  formData.append(`active`, active);

  const response = await API.patch(`/api/shop/static/${section}/`, formData, config);
  return response.data;
};

const updateDynamicArticle = async (section, attributeId, image, link, price, active) => {
  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  const formData = new FormData();
  formData.append(`image`, image.file ? image.file : image.url);
  formData.append(`link`, link);
  formData.append(`price`, price);
  formData.append(`active`, active);

  const response = await API.patch(`/api/shop/${section}/${attributeId}/`, formData, config);
  return response.data;
};

export default {
  fetchArticles,
  fetchColorArticle,
  fetchStyleArticle,
  fetchStaticArticle,
  updateDynamicArticle,
  updateStaticArticle,
};
