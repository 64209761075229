import styled from 'styled-components';

export default styled.h1`
  font-size: 60px;
  font-family: ${props => props.theme.fonts.black};
  line-height: 1.21;
  >span {
    font-family: ${props => props.theme.fonts.default};
  }
`;
