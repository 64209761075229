export const save = (key, obj) => {
  localStorage.setItem(key, JSON.stringify(obj));
};

export const get = (key) => {
  return JSON.parse(localStorage.getItem(key));
};

export const remove = key => {
  localStorage.removeItem(key);
};

export const clearAll = () => {
  localStorage.clear();
};
