import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button } from 'react-bootstrap';
import styled from 'styled-components';

import If from '../If/If';
import './Dialog.css';
import Spinner from '../Spinner/Spinner';

const DefaultButton = styled(Button)`
	background-color: #000;
	color: #fff;
	width: auto;
	padding: 12px 15px;
	user-select: none;
	font-weight: normal;
	text-align: center;
	white-space: nowrap;
	vertical-align: middle;
	touch-action: manipulation;
	cursor: pointer;
	font-size: 16px;
	display: flex;
	justify-content: center;
	align-items: center;
	position: relative;
	border: 0;
	border-radius: 0;
	background-image: none;
	:hover {
		color: #fff;
		text-decoration: none;
		background: #000;
	}
	:focus,
	:active,
	:active:focus {
		outline: none;
		border-radius: 0;
		background-color: #000;
		color: #fff;
	}
	:disabled {
		opacity: 0.75;
	}
`;
export default class Dialog extends Component {
	componentDidUpdate() {
		if (this.props.show) {
			document.body.classList.add('dialog-open');
			const modal = document.querySelector('.fade.in.modal');
			if (modal) {
				const modalMargin = 56; // 4em margin in css
				const scrollContainerHeight = document.querySelector(
					'.fade.in.modal .modal-content'
				).clientHeight;
				const modalCenter =
					(scrollContainerHeight - modal.clientHeight) / 2 + modalMargin * 2;
				this.animateScroll(modal, modalCenter, 50);
			}
		} else {
			document.body.classList.remove('dialog-open');
		}
	}

	componentWillUnmount() {
		document.body.classList.remove('dialog-open');
	}

	animateScroll = (element, to, duration) => {
		if (duration <= 0) {
			return;
		}
		var difference = to - element.scrollTop;
		var perTick = (difference / duration) * 10;

		setTimeout(() => {
			element.scrollTop = element.scrollTop + perTick;
			if (element.scrollTop === to) {
				return;
			}
			this.animateScroll(element, to, duration - 10);
		}, 10);
	};

	render() {
		const {
			containerClass = '',
			title,
			text,
			show,
			onReject,
			onConfirm,
			loading,
			cancelText = 'No',
			confirmText = 'Yes',
			canConfirm = true
		} = this.props;
		const dialogClass = `dialog-container ${containerClass}`;
		if (!show) {
			return null;
		}
		return (
			<div className={dialogClass}>
				<Modal.Dialog dialogClassName="dialog-component">
					{loading && <Spinner />}
					<If predicate={Boolean(title && title.length > 0)}>
						<Modal.Header>
							<Modal.Title>{title}</Modal.Title>
						</Modal.Header>
					</If>
					<Modal.Body>{this.props.children || text}</Modal.Body>
					<Modal.Footer>
						<DefaultButton onClick={onReject}>{cancelText}</DefaultButton>
						<DefaultButton
							onClick={canConfirm && onConfirm}
							className={`btn ${canConfirm ? '' : 'btn-disabled'}`}
						>
							{confirmText}
						</DefaultButton>
					</Modal.Footer>
				</Modal.Dialog>
			</div>
		);
	}
}

Dialog.propTypes = {
	cancelText: PropTypes.string,
	confirmText: PropTypes.string,
	canConfirm: PropTypes.bool,
	loading: PropTypes.bool,
	containerClass: PropTypes.string,
	title: PropTypes.string,
	text: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
	show: PropTypes.bool,
	onReject: PropTypes.func.isRequired,
	onConfirm: PropTypes.func.isRequired
};
