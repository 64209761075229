import API from 'utils/apiConfig';

const getPersonaPools = async () => {
  const response = await API.get(`api/quiz/manage/persona/`);
  return await response.data;
};

const getStylePools = async () => {
  const response = await API.get(`api/quiz/manage/style/`);
  return await response.data;
};

const getColorPools = async (colorGroup) => {
  const response = await API.get(`api/quiz/manage/color/?color_group=${colorGroup}`);
  return await response.data;
};

const updateColorSetColors = async (colorsList) => {
  const colors = colorsList.map((color) => ({
		uuid: color.uuid,
		value: color.value
	}));
  const data = { colors };

  const response = await API.put(`api/quiz/manage/color/update/`, data);
  return await response.data;
};

const getColorSets = async () => {
  const response = await API.get(`api/quiz/manage/colorsets/`);
  return await response.data;
};

const updateColorSet = async (setId, value) => {
  const data = {
    label: value
  };

  const response = await API.patch(`api/quiz/manage/colorsets/${setId}/`, data);
  return await response.data;
};

const uploadPicture = async (choiceId, picture) => {
  const formData = new FormData();
  formData.append(`value`, choiceId);
  formData.append(`file`, picture);

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  const response = await API.put(`/api/quiz/manage/image/${choiceId}/`, formData, config);
  return await response.data;
};

const createArtistrySet = async (artistryGroup, falselyChoice, truthyChoice) => {

  const formData = new FormData();
  formData.append(`type`, artistryGroup);
  formData.append(`falsely_choice`, falselyChoice);
  formData.append(`truthy_choice`, truthyChoice);

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  const response = await API.post(`/api/quiz/manage/artistry/`, formData, config);
  return await response.data;
};

const deleteArtistrySet = async setId => {
  const response = await API.delete(`api/quiz/manage/artistry/${setId}/`);
  return await response.data;
};

const createStylePool = async (images, title) => {
  const formData = new FormData();

  formData.append(`title`, title);
  images.forEach(item => {
    formData.append(`image_${item.id}`, item.image[0].file);
  });

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  const response = await API.post(`/api/quiz/manage/style/`, formData, config);
  return await response.data;
};

const changePersonaSetType = async ({setId, setType}) => {
  const response = await API.patch(`api/quiz/manage/persona/${setId}/`, {type: setType});
  return await response.data;
};

const deleteStyleLook = async lookId => {
  const response = await API.delete(`api/quiz/manage/stylelook/${lookId}/`);
  return await response.data;
};

const updateArtistryGroup = async (groupId, field, value) => {
  const data = {
    [field]: value
  };

  const response = await API.patch(`api/quiz/manage/artistrygroups/${groupId}/`, data);
  return response.data;
};

const updateArtistryTypeIcon = async (typeId, description, image=null) => {
  const formData = new FormData();

  if (image !== null) {
    formData.append(`icon`, image);
  }

  if (description !== null) {
    formData.append('description', description);
  }

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  const response = await API.patch(`api/quiz/manage/artistrytype/${typeId}/icon/`, formData, config);
  return response.data;
};

const updateArtistryTypeImage = async (typeId, image) => {
  const formData = new FormData();
  formData.append(`image`, image);

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  const response = await API.patch(`api/quiz/manage/artistrytype/${typeId}/image/`, formData, config);
  return response.data;
};

const updatePool = async (poolId, field, value) => {
  const data = {
    [field]: value
  };

  const response = await API.patch(`api/quiz/manage/pools/${poolId}/`, data);
  return response.data;
};

const updateStyleLook = async (lookId, field, value) => {
  const data = {
    [field]: value
  };

  const response = await API.patch(`api/quiz/manage/style/${lookId}/`, data);
  return response.data;
};

const fetchStyleTypes = async () => {
  const response = await API.get('api/quiz/manage/styletype/');
  return response.data;
};

const updateStyleType = async (typeId, field, value) => {
  const data = {
    [field]: value
  };

  const response = await API.patch(`api/quiz/manage/styletype/${typeId}/`, data);
  return response.data;
};

const updateStyleTypeIcon = async (typeId, description, image=null) => {
  const formData = new FormData();

  if (image !== null) {
    formData.append(`icon`, image);
  }

  if (description !== null) {
    formData.append('description', description);
  }

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  const response = await API.patch(`api/quiz/manage/styletype/${typeId}/icon/`, formData, config);
  return response.data;
};

const updateColorGroup = async (groupId, field, value) => {
  const data = {
    [field]: value
  };

  const response = await API.patch(`api/quiz/manage/colorgroups/${groupId}/`, data);
  return response.data;
};

const updateColorGroupImage = async (typeId, image) => {
  const formData = new FormData();
  formData.append(`image`, image);

  const config = {
    headers: {
      'content-type': 'multipart/form-data'
    }
  };

  const response = await API.patch(`api/quiz/manage/colorgroups/${typeId}/image/`, formData, config);
  return response.data;
};

const updateColorGroupCategory = async (groupId, field, value) => {
  const data = {
    [field]: value
  };

  const response = await API.patch(`api/quiz/manage/colorgroupcategories/${groupId}/`, data);
  return response.data;
};

const fetchPool = async (poolId) => {
  const response = await API.get(`api/quiz/manage/pools/${poolId}/`);
  return await response.data;
};

const addColorSetColors = async (colorSet, poolId, color1, color2, color3, color4, color5) => {
  const data = {
    color_set: colorSet,
    pool_id: poolId,
    color_1: color1,
    color_2: color2,
    color_3: color3,
    color_4: color4,
    color_5: color5
  };

  const response = await API.post(`api/quiz/manage/color/`, data);
  return response.data;
};

const deleteColorSet = async setId => {
  const response = await API.delete(`api/quiz/manage/color/${setId}/`);
  return await response.data;
};

const updateColorType = async (typeId, data) => {
  const response = await API.patch(`api/quiz/manage/colortype/${typeId}/`, data);
  return response.data;
};

export default {
  addColorSetColors,
  createArtistrySet,
  createStylePool,
  deleteArtistrySet,
  deleteColorSet,
  deleteStyleLook,
  getColorPools,
  getColorSets,
  getPersonaPools,
  getStylePools,
  fetchPool,
  fetchStyleTypes,
  updateArtistryGroup,
  updateArtistryTypeIcon,
  updateArtistryTypeImage,
  updateColorSetColors,
  updateColorGroup,
  updateColorGroupImage,
  updateColorGroupCategory,
  updateColorSet,
  uploadPicture,
  updatePool,
  updateColorType,
  updateStyleLook,
  updateStyleType,
  updateStyleTypeIcon,
  changePersonaSetType,
};
