import React, { Component } from 'react';

import { authApi, quizApi, profileApi } from 'api';
import { add, levels } from 'components/Notification/Notifications';
import { attachTokenToHeaders } from 'utils/apiConfig';
import { LOCALSTORAGE_KEYS } from 'utils/constants';
import {
	get as localStorageGet,
	save as localStorageSave,
  remove as localStorageRemove
} from 'utils/localstorageService';
import * as AuthService from 'utils/authService';
import Dialog from 'components/Dialog/Dialog';

const Context = React.createContext();
const ATTRIBUTE_KEYS = ['style', 'artistry', 'color'];

export class Provider extends Component {
	state = {
		user: null,
		appInitialized: false,
		isQuizCompleted: false,
		isDynamic: false,
		hasAcceptedPrivacyPolicy: false,
		loading: false
	};

	componentDidMount = () => {
		const isLoggedIn = AuthService.loggedIn();

		if (!isLoggedIn) {
			this.fetchUnauthUserQuiz();
			this.fetchPrivacyPolicyAcceptance();
			return;
		}

		this.fetchUserAndQuiz();
	};

	completeInitialization = () => {
		this.setState({ appInitialized: true });
	};

	fetchUnauthUserQuiz = async () => {
		const anonQuizUser = localStorageGet(LOCALSTORAGE_KEYS.ANON_QUIZ);

		if (anonQuizUser) {
			try {
				const progress = await quizApi.getQuizProgressByUsername(anonQuizUser);
				this.setQuizProgress(progress);
			} catch (error) {
				const message = 'Could not fetch quiz progress';
				add(message, levels.ERROR);
				console.error(message, error);
			}
		}
		this.completeInitialization();
	};

	fetchUserAndQuiz = async () => {
		attachTokenToHeaders();

		let progress, user;
		try {
			[progress, user] = await Promise.all([
				quizApi.getQuizProgress(),
				authApi.getCurrentUser()
			]);
			this.setState({
				user,
				hasAcceptedPrivacyPolicy: user?.profile?.accepted_cookie_policy
			});
			localStorageSave(LOCALSTORAGE_KEYS.PRIVACY_POLICY, {
				consent: user?.profile?.accepted_cookie_policy
			});
			this.setQuizProgress(progress);
		} catch (error) {
			add('Whoops, something went wrong', levels.ERROR);
			console.error('Whoops, something went wrong', error);
		}

		this.completeInitialization();
	};

	handleLogin = async (username, password) => {
		try {
			const user = await authApi.login(username, password);
			this.setCurrentUser(user);
			await this.fetchAndSetQuizProgress();
			this.setState({
				hasAcceptedPrivacyPolicy: user?.profile?.accepted_cookie_policy
			});
			localStorageSave(LOCALSTORAGE_KEYS.PRIVACY_POLICY, {
				consent: user?.profile?.accepted_cookie_policy
			});

			return user;
		} catch (err) {
			console.error(`Logging error: `, err.data);
			const messages = Object.values(err.data);

			if (Array.isArray(messages)) {
				messages.forEach((message) => {
					add(message, levels.ERROR);
				});
				throw err;
			}

			add(messages, levels.ERROR);
			throw err;
		}
	};

	handleGoogleLogin = async (code) => {
		try {
			const user = await authApi.googleLogin(code);
			this.setCurrentUser(user);
			await this.fetchAndSetQuizProgress();

			return user;
		} catch (err) {
			console.error(`Google logging error: `, err.data);
			const messages = Object.values(err.data);

			if (Array.isArray(messages)) {
				messages.forEach((message) => {
					add(message, levels.ERROR);
				});
				throw err;
			}

			add(messages, levels.ERROR);
			throw err;
		}
	};

	handleFacebookLogin = async (code) => {
		try {
			const user = await authApi.facebookLogin(code);
			this.setCurrentUser(user);
			await this.fetchAndSetQuizProgress();

			return user;
		} catch (err) {
			console.error(`Facebook logging error: `, err.data);
			const messages = Object.values(err.data);

			if (Array.isArray(messages)) {
				messages.forEach((message_1) => {
					add(message_1, levels.ERROR);
				});
				throw err;
			}

			add(messages, levels.ERROR);
			throw err;
		}
	};

	handleLogout = () => {
    localStorageSave(LOCALSTORAGE_KEYS.PRIVACY_POLICY, {
			consent: this.state.user?.profile?.accepted_cookie_policy
		});

		authApi.logout();

    console.log("Policy on logut: ", this.state.user?.profile?.accepted_cookie_policy);
  
    localStorageRemove(LOCALSTORAGE_KEYS.ANON_QUIZ);

		this.setState({
			isQuizCompleted: false,
			hasAcceptedPrivacyPolicy: this.state.user?.profile
				?.accepted_cookie_policy,
			user: null,
		});		
	};

	setCurrentUser = (user) => {
		this.setState({ user });
		return user;
	};

	fetchAndSetQuizProgress = async () => {
		const quizProgress = await quizApi.getQuizProgress();
		this.setQuizProgress(quizProgress);
	};

	setQuizProgress = (progress) => {
		const isCompleted =
			progress &&
			Object.keys(progress).every((key) => progress[key]) &&
			ATTRIBUTE_KEYS.every((attribute) =>
				Object.keys(progress).includes(attribute)
			);

		this.setState({ progress, isQuizCompleted: isCompleted });
	};

	retakeQuiz = async () => {
		const { user } = this.state;
		const anonUser = localStorageGet(LOCALSTORAGE_KEYS.ANON_QUIZ);

		try {
			const username = user?.username || anonUser;
			if (!username) {
				console.error('Cannot find a user to delete quiz progress.');
				add('Whoops, something went wrong', levels.ERROR);
				return;
			}
			await quizApi.resetQuizResults(username);
		} catch (error) {
			console.error(error);
			add('Whoops, something went wrong', levels.ERROR);
			return;
		}

		this.setQuizProgress(null);
	};

	toggleIsDynamic = (isDynamic) => {
		this.setState({ isDynamic });
	};

	confirm = ({ title, text }) => {
		return new Promise((resolve) => {
			this.setState({
				isDialogVisible: true,
				dialogTitle: title,
				dialogText: text,
				onDialogConfirm: () => {
					resolve(true);
					this.setState({ isDialogVisible: false });
				},
				onDialogReject: () => {
					resolve(false);
					this.setState({ isDialogVisible: false });
				}
			});
		});
	};

	fetchPrivacyPolicyAcceptance = () => {
		const getPrivacyPolicyAcceptance = localStorageGet(
			LOCALSTORAGE_KEYS.PRIVACY_POLICY
		)?.consent;

		if (getPrivacyPolicyAcceptance) {
			this.setState({ hasAcceptedPrivacyPolicy: getPrivacyPolicyAcceptance });
		}
	};

	acceptPrivacyPolicy = async () => {
		const { progress, user } = this.state;
		const anonQuizUser = localStorageGet(LOCALSTORAGE_KEYS.ANON_QUIZ);
		if (!progress) {
			this.setState({ hasAcceptedPrivacyPolicy: true });
			localStorageSave(LOCALSTORAGE_KEYS.PRIVACY_POLICY, { consent: true });
		} else if (user) {
			try {
				this.setState({ loading: true });
				await profileApi.acceptCookies(user?.username);
				await profileApi.acceptPrivacyPolicy(user?.username);
				this.setState({ hasAcceptedPrivacyPolicy: true });
				localStorageSave(LOCALSTORAGE_KEYS.PRIVACY_POLICY, { consent: true });
			} catch (error) {
				console.error(error);
				add('Whoops, something went wrong', levels.ERROR);
			} finally {
				this.setState({ loading: false });
      }
		} else if (anonQuizUser) {
			try {
				this.setState({ loading: true });
				await profileApi.acceptAnonymousCookies(anonQuizUser);
				await profileApi.acceptAnonymousPrivacyPolicy(anonQuizUser);
				this.setState({ hasAcceptedPrivacyPolicy: true });
				localStorageSave(LOCALSTORAGE_KEYS.PRIVACY_POLICY, { consent: true });
			} catch (error) {
				console.error(error);
				add('Whoops, something went wrong', levels.ERROR);
			} finally {
				this.setState({ loading: false });
			}
		} 
	};

	render() {
		const {
			isDialogVisible,
			dialogTitle,
			dialogText,
			onDialogConfirm,
			onDialogReject
		} = this.state;

		return (
			<Context.Provider
				value={{
					state: this.state,
					actions: {
						handleLogin: this.handleLogin,
						handleGoogleLogin: this.handleGoogleLogin,
						handleFacebookLogin: this.handleFacebookLogin,
						handleLogout: this.handleLogout,
						setCurrentUser: this.setCurrentUser,
						setQuizProgress: this.setQuizProgress,
						toggleIsDynamic: this.toggleIsDynamic,
						fetchUserAndQuiz: this.fetchUserAndQuiz,
						retakeQuiz: this.retakeQuiz,
						confirm: this.confirm,
						acceptPrivacyPolicy: this.acceptPrivacyPolicy
					}
				}}
			>
				{this.props.children}
				<Dialog
					show={isDialogVisible}
					title={dialogTitle}
					text={dialogText}
					onConfirm={onDialogConfirm}
					onReject={onDialogReject}
				/>
			</Context.Provider>
		);
	}
}

export const Consumer = Context.Consumer;
export const RootContext = Context;
