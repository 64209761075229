import styled from 'styled-components';
import { Panel } from '../Panel/Panel';

export const Section = styled.section`
	position: relative;
	&:before {
		content: '';
		background-image: url('/images/rebranding/LearnSectionMaskSmall.png');
		max-width: 460px;
		max-height: 625px;
		height: 100%;
		width: 100%;
		left: 0;
		top: 5rem;
		position: absolute;
		background-repeat: no-repeat;
		@media ${(props) => props.theme.device.mtablet} {
			display: none;
		}
    z-index: -1;
	}
	&:after {
		content: '';
		background-image: url('/images/rebranding/FooterSplashSmall.png');
		max-width: 460px;
		max-height: 625px;
		height: 100%;
		width: 100%;
		right: -10rem;
		top: 25rem;
		position: absolute;
		background-repeat: no-repeat;
		@media ${(props) => props.theme.device.mtablet} {
			display: none;
		}
    z-index: -1;
	}
`;

export const PersonaHeader = styled.span`
	width: 485px;
	height: 75px;
	color: ${(props) => props.theme.colors.activeText};
	@media ${(props) => props.theme.device.mMobile} {
		font-size: 32px;
	}
`;

export const PersonaHeaderWrapper = styled.div`
	text-align: center;
	font-size: 3em;
	font-family: ${(props) => props.theme.fonts.regular};
	font-weight: normal;
	margin-bottom: 3rem;
`;

export const PersonaBodyWrapper = styled.div`
	text-align: center;
	font-size: 28px;
	font-family: ${(props) => props.theme.fonts.regular};
	font-weight: normal;
	margin-bottom: 3rem;
	line-height: 1.2;
	@media ${(props) => props.theme.device.mMobile} {
		font-size: 21px;
		margin-bottom: 1rem;
	}
`;

export const LinkButton = styled.span`
	font-size: 28px;
	font-family: ${(props) => props.theme.fonts.black};
	color: ${(props) => props.theme.colors.link};
	text-decoration: none;
	cursor: pointer;
	@media ${(props) => props.theme.device.mtablet} {
		font-size: 21px;
		margin-bottom: 10px;
	}
`;

export const BlockTypeText = styled.div`
	font-size: 28px;
	font-family: ${(props) => props.theme.fonts.black};
	margin-bottom: 15px;

	@media ${(props) => props.theme.device.mtablet} {
		font-size: 21px;
		margin-bottom: 10px;
	}
`;

export const BlockNameText = styled.div`
	font-size: 28px;
	font-family: ${(props) => props.theme.fonts.activeText};
	margin-bottom: 15px;
  line-height: 32px;
	@media ${(props) => props.theme.device.mtablet} {
		font-size: 21px;
		margin-bottom: 10px;
	}
`;

export const BlockTypesWrapper = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin-bottom: 1rem;
	flex-direction: column;
  flex: 1 1 30%;
  justify-content: space-between;

	@media ${(props) => props.theme.device.mMobile} {
		width: 100%;
		display: flex;
		flex-direction: column;
	}

	.glyphicon-triangle-bottom {
		position: absolute;
		right: 30px;
		top: 16px;
		@media only ${(props) => props.theme.device.tablet} {
			right: 65px;
		}
	}

	span {
		@media ${(props) => props.theme.device.mMobile} {
			min-height: auto;
		}
	}

	&:last-child {
		&:after {
			content: '';
			display: block;
			width: 1px;
			border: none;
		}
	}
`;

export const ResultImage = styled.img`
	width: 70px;
	height: 70px;
	@media ${(props) => props.theme.device.mtablet} {
		width: 40px;
		height: 40px;
	}
`;

export const ResultIconWrapper = styled.div`
	margin-bottom: 30px;
	@media ${(props) => props.theme.device.mMobile} {
		margin-bottom: 15px;
	}
`;

export const ChangeSelectionWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

export const ItemIconsWrapper = styled.div`
  display: flex;
  ${(props) =>
		props.fillspace &&
		`
    justify-content: space-between;
  `}
  @media ${(props) => props.theme.device.mlaptopL} {
    flex-wrap: wrap;
    justify-content: flex-start;
  }
`;

export const CircleBoxWrapper = styled.div`
	min-width: 55px;
`;

export const SelectedItemHeader = styled.div`
	margin-top: 40px;
	margin-bottom: 30px;
	font-family: ${(props) => props.theme.fonts.black};
`;

export const SelectedItemDescription = styled.div`
	text-align: justify;
	text-justify: inter-word;
`;

export const QuizResultsWrapper = styled.div`
  max-width: 1170px;
	text-align: center;
	padding: 30px 0;
	font-size: 2.6em;
  display: flex;
	@media ${(props) => props.theme.device.mtablet} {
		padding: 0;
		font-size: 2em;
		width: 100%;
		flex-direction: column;
	}
`;

export const PersonaHeaderPanel = styled(Panel)`
	display: flex;
	flex-direction: column;
	align-items: center;
`;

export const SuggestedForYouWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
	margin-top: 5.6rem;
	margin-bottom: 5.6rem;
`;

export const SectionTitle = styled.span`
  font-weight: ${ props => props.weight || 'normal'};
  font-size: 3em;
  color: #000000;
`;

export const CardsWrapper = styled.div`
  margin-top: 60px;
  div.grid-container {
    display: flex;
    justify-content: center;
  }
`;

export const FooterWrapper = styled.div`
	&:after {
		content: '';
		background-image: url('/images/rebranding/FooterSplashSmall.png');
		max-width: 460px;
		max-height: 625px;
		height: 100%;
		width: 100%;
		right: -10rem;
		top: 25rem;
		position: absolute;
		background-repeat: no-repeat;
	}
`;

export const LeftStyleImg = styled.div`
		content: '';
		background-image: url('/images/rebranding/HeroImage.png');
		max-height: 625px;
		height: 100%;
		width: 100%;
		left: -45rem;
		top: 60rem;
		position: absolute;
		background-repeat: no-repeat;
`;

export const Message = styled.div`
	text-align: center;
	font-size: 28px;
	margin-bottom: 2rem;
`;

export const Block = styled.div`
	text-align: center;
	margin-bottom: 5rem;
	button {
		display: inline-block;
		font-size: 18px;
	}
`;