import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import If from 'components/If/If';
import RetakeQuiz from 'components/New/Containers/RetakeQuiz/RetakeQuiz';
import Spinner from 'components/Spinner/Spinner';
import QuizResultsItem from 'components/Persona/QuizResultsItem';
import { Link } from 'react-router-dom';
import { withRootContext } from 'context/withRootContext';
import { withQuizContext } from 'context/withQuizContext';
import { Button, H1, Text, QuizFrame } from './Quiz.styled';
import { useBoolean } from '../../utils/hooks';
import { QuizResultsWrapper } from 'components/Persona/Persona.styled';
import { quizApi } from 'api';
import { BLOCK_TYPE } from 'utils/constants';

const Recap = (props) => {
	const [loading, startLoading, stopLoading] = useBoolean(false);
	const [attributes, setAttributes] = useState(null);
	const { actions } = props.quizContext;
	const { results } = props.quizContext.state;
	const { user } = props.context.state;

	useEffect(() => {
		const fetchAttributeTypes = async () => {
			startLoading();
			const items = await quizApi.getAttributeTypes();
			const formattedAttributes = {};

			Object.keys(items).forEach(function (key) {
				formattedAttributes[key] = items[key].map((a) => {
					return {
						id: a.id,
						name: a.title,
						description: a.description,
						persona: a.persona,
						pictures: a.medias.filter((media) => media.is_image === true),
						image: a.image,
						icon: a.icon,
						color: a.color_hex
					};
				});
			});
			setAttributes(formattedAttributes);
			stopLoading();
		};
		fetchAttributeTypes();
		// eslint-disable-next-line
	}, []);

	if (loading) {
		return <Spinner />;
	}

	return (
		<QuizFrame>
			<Text>
				<H1>These are your Quiz results</H1>
				Thank you for taking the Quiz! Now you can{' '}
				<Link to="/learn">Learn</Link> about your own Style, Artistry and
				Coloring, and also <Link to="/discover">Discover</Link> more about The
				Art of Dress.
			</Text>
			<QuizResultsWrapper>
				<QuizResultsItem
					block={results && results.style}
					blockType={BLOCK_TYPE.STYLE}
					persona={results}
					attributes={attributes}
					changeQuizResult={actions.changeQuizResult}
				/>
				<QuizResultsItem
					block={results && results.artistry}
					blockType={BLOCK_TYPE.ARTISTRY}
					persona={results}
					attributes={attributes}
					changeQuizResult={actions.changeQuizResult}
				/>
				<QuizResultsItem
					block={results && results.color}
					blockType={BLOCK_TYPE.COLOR}
					persona={results}
					attributes={attributes}
					changeQuizResult={actions.changeQuizResult}
				/>
			</QuizResultsWrapper>
			<div className="recap-button-wrap">
				<If predicate={!user}>
					<Button aslink to="/register">
						Sign up to save
					</Button>
				</If>
			</div>
			<RetakeQuiz />
		</QuizFrame>
	);
};

Recap.propTypes = {
	quizContext: PropTypes.shape({
		state: PropTypes.object.isRequired
	}),
	context: PropTypes.shape({
		state: PropTypes.object.isRequired
	})
};

export default withRootContext(withQuizContext(Recap));
