import React, { useContext, useState } from 'react';
import { RootContext } from 'context/RootContext';
import Dialog from 'components/Dialog/Dialog';
import {
	StickySection,
	LinkButton,
	Text,
	Title,
	StyledButton,
	Container,
	Image,
	Block,
	Close
} from 'components/StickyConsentPopup/StickyPopup.styled';

const StickyPopup = () => {
	const rootContext = useContext(RootContext);
	const { acceptPrivacyPolicy } = rootContext.actions;
	const { loading } = rootContext.state;
	const [dialog, setOpenDialog] = useState(false);

	const openDialog = () => {
		setOpenDialog(true);
	};

	return (
		<>
			<StickySection>
				<Block>
					<Close onClick={openDialog} />
				</Block>
				<Container>
					<Image src="/images/rebranding/cookie.png" alt="cookie icon" />
				</Container>
				<Title>Cookies</Title>
				<Text>
					Cookies help us deliver the best experience on our website. By using
					our website, you agree to the use of cookies. Find out how we use
					cookies.
				</Text>
				<div>
					<StyledButton disabled={loading} onClick={acceptPrivacyPolicy}>
						Accept
					</StyledButton>
				</div>
				<LinkButton to={'/cookie-policy'}>Cookie Policy</LinkButton>
			</StickySection>
			<Dialog
				show={dialog}
				title="Are you sure?"
				text="Are you sure you want to decline these terms and leave our site?"
				onReject={() => (window.location.href = 'https://www.google.com/')}
				onConfirm={() => setOpenDialog(false)}
				cancelText="Leave site"
				confirmText="Stay"
			/>
		</>
	);
};

export default StickyPopup;
