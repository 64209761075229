import API from 'utils/apiConfig';

const BASE_URL = 'api/notifications/banner';

const getAllNotifications = async ({
	searchTerm,
	sortOrder,
	pageSize,
	pageNumber,
	filterActiveNotifications = true
}) => {
	let url = `${BASE_URL}/?is_active=${filterActiveNotifications}`;

	if (searchTerm) {
		url = `${url}&search=${searchTerm}`;
	}

	if (sortOrder?.value && sortOrder?.order) {
		let sortValue = sortOrder.value;

		if (sortOrder?.order === 'desc') {
			sortValue = `-${sortValue}`;
		}

		url = `${url}&ordering=${sortValue}`;
	}

	if (pageSize && pageNumber) {
		url = `${url}&page_size=${pageSize}&page=${pageNumber}`;
	}

	const response = await API.get(url);

	return response.data;
};

const getActiveNotifications = async (pageNumber = 1, pageSize = 10) => {
	const response = await API.get(`${BASE_URL}/active/?page_size=${pageSize}&page=${pageNumber}`);

	return response?.data?.results || [];
};

const createNotification = async ({ data }) => {
	const response = await API.post(`${BASE_URL}/`, data);

	return response.data;
};

const updateNotification = async ({ id, data }) => {
	const response = await API.patch(`${BASE_URL}/${id}/`, data);

	return response.data;
};

const deleteNotification = async ({ id }) => {
	const response = await API.delete(`${BASE_URL}/${id}/`);

	return response.data;
};

const getNotificationById = async ({ id }) => {
	const response = await API.get(`${BASE_URL}/${id}/`);

	return response.data;
};

export default {
	getAllNotifications,
	getActiveNotifications,
	createNotification,
	updateNotification,
	deleteNotification,
	getNotificationById
};
