import React, { Component } from 'react';
import { PropTypes } from 'prop-types';
import { NavLink, withRouter } from 'react-router-dom';
import { produce } from 'immer';

import HeaderNotification from './HeaderNotification';

import './Navigation.css';

class NavContent extends Component {
	state = {
		isCheckedMenu: false
	};

	handleLogoutClick = (handleLogout) => {
		const { history } = this.props;

		handleLogout();
		history.push('/login');
	};

	toggleMenu = () => {
		this.setState(
			produce((draft) => {
				draft.isCheckedMenu = !draft.isCheckedMenu;
			})
		);
	};

	handleMenuClick = (event) => {
		if (event.target.hasAttribute('href')) {
			this.toggleMenu();
		}
	};

	handleLogoutClick = (e) => {
		e.preventDefault();
		this.toggleMenu();

		const { history } = this.props;
		const { handleLogout } = this.props.context.actions;

		handleLogout();
		history.push('/login');
	};

	render() {
		const { user, isDynamic } = this.props.context.state;
		const { isCheckedMenu } = this.state;

		const mobileNavView = isDynamic ? `isScrolling ` : '';

		const isQuizCompleted = this.props.context.state.isQuizCompleted;

		return (
			<div className="mobile-navigation-conteiner">
				<header>
					<nav id="menu">
						<input
							type="checkbox"
							id="tm"
							checked={isCheckedMenu}
							onChange={this.toggleMenu}
						/>
						<div id="toggle-menu">
							{!isCheckedMenu && <HeaderNotification />}
							<div
								className={`${mobileNavView} ${
									isCheckedMenu ? 'checked-tm-menu' : 'tm-menu'
								}`}
							>
								<div className="menu-toggle">
									<div
										style={{
											justifyContent: 'flex-end',
											padding: '0px 10px',
											display: 'flex'
										}}
									>
										{!isCheckedMenu && (
											<img
												src="/images/rebranding/verticalSeparator.png"
												alt="Separator"
											/>
										)}
										<label htmlFor="tm">
											<span>
												{isCheckedMenu ? (
													<div style={{ paddingRight: 10 }}>Close</div>
												) : (
													'Menu'
												)}
											</span>
										</label>
									</div>
								</div>
							</div>
						</div>
						{/* {!isCheckedMenu && <HeaderNotification />} */}
						<ul className="main-menu" onClick={this.handleMenuClick}>
							<li className="menu"></li>
							<li className="menu-title">The Art of Dress</li>
							<li>
								<NavLink to="/">Home</NavLink>
							</li>
							<li>
								<NavLink to="/about">About</NavLink>
							</li>
							<li>
								<NavLink to="/learn">Learn</NavLink>
							</li>
							<li>
								<NavLink to="/discover">Discover</NavLink>
							</li>
							<li>
								<NavLink to="/shop">Shop</NavLink>
							</li>
							<li>
								<NavLink to="/contact-us">Contact us</NavLink>
							</li>
							<li>
								{!isQuizCompleted && <NavLink to="/quiz">Quiz</NavLink>}
								{isQuizCompleted && <NavLink to="/persona">Persona</NavLink>}
							</li>
							{user ? (
								<li>
									<NavLink onClick={this.handleLogoutClick} to="/login">
										Sign out
									</NavLink>
								</li>
							) : (
								<React.Fragment>
									<li>
										<NavLink to="/login">Sign in</NavLink>
									</li>
									<li>
										<NavLink to="/register">Register</NavLink>
									</li>
								</React.Fragment>
							)}
						</ul>
					</nav>
				</header>
			</div>
		);
	}
}

export default withRouter(NavContent);

NavContent.propTypes = {
	keyProp: PropTypes.string,
	history: PropTypes.object.isRequired,
	location: PropTypes.object.isRequired,
	context: PropTypes.shape({
		state: PropTypes.object.isRequired,
		actions: PropTypes.object.isRequired
	})
};
