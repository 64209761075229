import React from 'react';
import { PropTypes } from 'prop-types';
import classNames from 'classnames';
import './Panel.css';

export class Panel extends React.Component {
	render() {
		const panelClasses = classNames('taod-panel', this.props.className);
		return (
			<div className={panelClasses}>
				<div className="taod-panel-content">{this.props.children}</div>
			</div>
		);
	}
}

Panel.propTypes = {
	className: PropTypes.string
};
