import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Grid } from 'react-bootstrap';
import { Buttons, H1 as BaseH1 } from '../../styles/styled/components';

export const Container = styled(Grid)`
	display: flex;
	justify-content: center;
	align-items: center;
	@media ${(props) => props.theme.device.mlaptop} {
		display: block;
		margin-top: 10rem;
	}
`;

export const QuizFrame = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`;

export const QuestionSection = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`;

export const Button = styled(Buttons.Styled).attrs((props) => ({
	...(props.aslink && { as: Link })
}))`
	display: inline-block;
	font-size: 28px;
	padding: 20px 50px;
	@media ${(props) => props.theme.device.mlaptop} {
		font-size: 20px;
		padding: 10px 40px;
	}
`;

export const ButtonDefault = styled(Buttons.Default)`
	padding: 15.5px 60.1px 15.5px 60.1px;
	font-size: 20px;
	@media ${(props) => props.theme.device.mlaptop} {
		padding: 10px 40px;
	}
`;

export const ButtonHollow = styled(Buttons.Hollow)`
	padding: 15.5px 60.1px 15.5px 60.1px;
	font-size: 20px;
	@media ${(props) => props.theme.device.mlaptop} {
		padding: 10px 40px;
	}
`;

export const H1 = styled(BaseH1).attrs({
	as: 'div'
})`
	color: #000;
	margin-bottom: 2rem;
	@media ${(props) => props.theme.device.mlaptop} {
		font-size: 28px;
		margin-bottom: 2rem;
	}
`;

export const Text = styled.div`
	line-height: 30px;
	color: ${(props) => props.theme.colors.activeText};
	font-size: 1.875em;
	text-align: center;
	width: 100%;
	margin-top: 1rem;
	margin-bottom: 1.5rem;
	@media ${(props) => props.theme.device.mlaptop} {
		font-size: 20px;
		margin-bottom: 2rem;
		line-height: 25px;
		margin-top: 1rem;
	}
`;

export const StepTitle = styled(H1)`
	margin-bottom: 10px;
	font-size: 40px;
	@media ${(props) => props.theme.device.mlaptop} {
		font-size: 25px;
		margin-bottom: 5px;
	}
`;

export const StepTitleTextWrapper = styled(Text)`
	text-align: left;
	@media ${(props) => props.theme.device.mlaptop} {
		margin-bottom: 0.5rem;
		margin-top: 0.5rem;
	}
	span {
		@media ${(props) => props.theme.device.mlaptop} {
			font-size: 16px;
			line-height: 18px;
		}
	}
`;

export const CenterContent = styled.div`
	display: flex;
	justify-content: center;
	flex-direction: column;
	align-items: center;
`;

export const BackgroundGraphics = styled.div`
	height: calc(100% - 74px);
	width: 100%;
	background-image: url('/images/rebranding/quizGraphic01.png'),
		url('/images/rebranding/quizGraphic02.png');
	background-position: center left 0, center right 0;
	background-size: 200px 550px, 120px 570px;
	z-index: 0;
	background-repeat: no-repeat;
	@media ${(props) => props.theme.device.mlaptop} {
		background-position: bottom left -50px, top right -22px;
		background-size: calc(288px / 2) calc(637px / 2),
			calc(131px / 2) calc(590px / 2);
	}
`;

export const QuizFooter = styled.div`
	margin-top: 1rem;
	display: flex;
	justify-content: flex-end;
	width: 100%;
	${(props) =>
		props.answers.length > 0 &&
		`
    justify-content: space-between;
    width: 100%;
  `};
	@media ${(props) => props.theme.device.mlaptop} {
		margin-top: 0;
	}
`;

export const QuestionWrapper = styled.div`
	min-height: 420px;
`;

export const RecapContainer = styled.div`
	display: flex;
	justify-content: center;
	align-items: center;
	height: 100%;
	flex-direction: column;
`;

export const RetakeQuestion = styled.span`
	margin-top: 2rem;
	font-size: 28px;
	font-family: overlock-black, Helvetica, Arial, sans-serif;
	color: rgb(14, 136, 162);
	text-decoration: none;
	cursor: pointer;
	@media ${(props) => props.theme.device.mtablet} {
		font-size: 21px;
		margin-bottom: 1rem;
	}
`;

export const ArtistryDescription = styled.li`
	font-size: 28px;
	margin-bottom: 15px;
	margin-bottom: 2rem;
`;

export const RecapImg = styled.img`
	width: 70px;
	height: 70px;
	margin-bottom: 1rem;
`;

export const Subtitle = styled.span`
	font-size: 1em;
	font-family: overlock-regular;
	display: block;
	font-size: 20px;
`;

export const SectionName = styled.span`
	text-transform: capitalize;
`;

export const Instruction = styled.div`
	text-align: center;
	font-family: overlock-regular;
	color: #b5b5be;
	width: 100%;
`;
export const InstructionText = styled.div`
	@media ${(props) => props.theme.device.mtablet} {
		margin-bottom: 10px;
	}
	position: relative;
	&:before {
		content: '';
		position: absolute;
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E2E2EAFF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e");
		height: 1px;
		width: calc(60% - 250px);
		left: 0;
		top: 12px;
		@media ${(props) => props.theme.device.mtablet} {
			width: calc(63% - 250px);
		}
	}
	&:after {
		content: '';
		position: absolute;
		background-image: url("data:image/svg+xml,%3csvg width='100%25' height='100%25' xmlns='http://www.w3.org/2000/svg'%3e%3crect width='100%25' height='100%25' fill='none' stroke='%23E2E2EAFF' stroke-width='2' stroke-dasharray='6%2c 14' stroke-dashoffset='10' stroke-linecap='square'/%3e%3c/svg%3e");
		height: 1px;
		width: calc(60% - 250px);
		right: 0;
		top: 12px;
		@media ${(props) => props.theme.device.mtablet} {
			width: calc(63% - 250px);
		}
	}
`;
