import React from 'react';
import PropTypes from 'prop-types';
import {Helmet} from "react-helmet";


const OgHelmet = ({title, url, author, image, description, children}) => {
  return (
    <Helmet>
      <title>{`The Art of Dress${title ? ` - ${title}` : ''}`}</title>
      <meta name="og:title" content={title || 'The Art of Dress'}/>
      {url && <meta name="og:url" content={url}/>}
      {description && <meta name="description" content={description}/>}
      {description && <meta name="og:description" content={description}/>}
      {image && <meta name="og:image" content={image}/>}
      {author && <meta name="twitter:author" content={author}/>}
      {children}
    </Helmet>
  );
};

OgHelmet.propTypes = {
  /**
   * Page title, used in `title` and `og:title`
   */
  title: PropTypes.string,
  /**
   * page description, used in `description` and `og:description`
   */
  description: PropTypes.string,
  /**
   * page image, used `og:image`
   */
  image: PropTypes.string,
  /**
   * page url, used `og:url`
   */
  url: PropTypes.string,
  /**
   * page author, used `twitter:author`, uses twitter format `@authorlink`
   */
  author: PropTypes.string,
};


export default OgHelmet;
