import React from 'react';
import styled from 'styled-components';
import { Input as Field } from '@bootstrap-styled/v4';

const Error = styled.label`
	height: 12px;
	font-size: 12px;
	color: ${({ theme }) => theme.colors.danger};
`;

const Component = styled(Field)`
	${(props) =>
		props.shadow &&
		`
    background: ${props.theme.colors.background} !important;
    box-shadow: ${props.theme.boxShadow.light.primary} !important;
  `}
	font-family: ${(props) => props.theme.fonts.black} !important;
	color: #000;
	height: 48px;
	padding: 10px 16px;
	line-height: 1.3333333;
	border-radius: 0;
	&:focus {
		outline: none;
	}

	${({ haserror, theme }) =>
		haserror === 'true' && `border-color: ${theme.colors.danger};`}
`;

const Input = ({ errors, inputRef, name, ...rest }) => {
	const errorMessage = errors[name]?.message;

	return (
		<div>
			<Component
				{...rest}
				haserror={(errorMessage !== undefined).toString()}
				name={name}
				ref={inputRef}
			/>
			<Error>{errorMessage || ''}</Error>
		</div>
	);
};

export default Input;
