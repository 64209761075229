import { lazy } from 'react';
import { Route } from 'react-router-dom';
import { ROUTE_PATHS } from 'routePaths.const';

// TODO: implement lazy loading for the Quiz component
//       Currently a context error occurs when lazy loading the component
import Quiz from './components/Quiz/Quiz';

const AuthRoute = lazy(() => import('./components/AuthRoute/AuthRoute'));
const About = lazy(() => import('./components/About/About'));
const Shop = lazy(() => import('./components/Shop/Shop'));
const Auth = lazy(() => import('./components/Auth/Auth'));
const BlogContainer = lazy(() => import('./components/Blog/BlogContainer'));
const BlogArticle = lazy(() => import('./components/Blog/BlogArticle'));
const Dashboard = lazy(() => import('./components/Dashboard/Dashboard'));
const Home = lazy(() => import('./components/Home/Home'));
const LearnContainer = lazy(() => import('components/Learn/LearnContainer'));
const Login = lazy(() => import('./components/Login/Login'));
const NotFound = lazy(() => import('./components/NotFound/NotFound'));
const ForgotPassword = lazy(() =>
	import('./components/Password/ForgotPassword')
);
const ResetPassword = lazy(() => import('./components/Password/ResetPassword'));
const Persona = lazy(() => import('./components/Persona/Persona'));
const Profile = lazy(() => import('./components/Profile/UserData'));
const Register = lazy(() => import('./components/Register/Register'));
const Search = lazy(() => import('./components/Search/Search'));
const CookiePolicy = lazy(() => import('components/PolicyPages/CookiePolicy'));
const PrivacyPolicy = lazy(() =>
	import('components/PolicyPages/PrivacyPolicy')
);
const ContactUs = lazy(() => import('components/ContactUs/ContactUs'));

export const ROUTES = {
	HOME: {
		route: Route,
		path: ROUTE_PATHS.HOME,
		exact: true,
		component: Home
	},
	COOKIE_POLICY: {
		route: Route,
		path: ROUTE_PATHS.COOKIE_POLICY,
		component: CookiePolicy
	},
	PRIVACY_POLICY: {
		route: Route,
		path: ROUTE_PATHS.PRIVACY_POLICY,
		component: PrivacyPolicy
	},
	ABOUT: {
		route: Route,
		path: ROUTE_PATHS.ABOUT,
		component: About
	},
	SHOP: {
		route: Route,
		path: ROUTE_PATHS.SHOP,
		component: Shop
	},
	DISCOVER: {
		route: Route,
		exact: true,
		path: ROUTE_PATHS.DISCOVER,
		component: BlogContainer
	},
	DISCOVER_ARTICLE: {
		route: Route,
		exact: true,
		path: ROUTE_PATHS.DISCOVER_ARTICLE,
		component: BlogArticle
	},
	SEARCH: {
		route: Route,
		path: ROUTE_PATHS.SEARCH,
		component: Search
	},
	LOGIN: {
		route: Route,
		path: ROUTE_PATHS.LOGIN,
		component: Login
	},
	REGISTER: {
		route: Route,
		path: ROUTE_PATHS.REGISTER,
		component: Register
	},
	FORGOT_PASSWORD: {
		route: Route,
		path: ROUTE_PATHS.FORGOT_PASSWORD,
		component: ForgotPassword
	},
	RESET_PASSWORD: {
		route: Route,
		path: ROUTE_PATHS.RESET_PASSWORD,
		component: ResetPassword
	},
	AUTH: {
		route: Route,
		path: ROUTE_PATHS.AUTH,
		component: Auth
	},
	QUIZ: {
		route: Route,
		path: ROUTE_PATHS.QUIZ,
		component: Quiz
	},
	PERSONA: {
		route: Route,
		path: ROUTE_PATHS.PERSONA,
		component: Persona
	},
	LEARN: {
		route: Route,
		path: ROUTE_PATHS.LEARN,
		component: LearnContainer
	},
	PROFILE: {
		route: AuthRoute,
		path: ROUTE_PATHS.PROFILE,
		component: Profile
	},
	DASHBOARD: {
		route: AuthRoute,
		path: ROUTE_PATHS.DASHBOARD,
		component: Dashboard,
		admin: true
	},
	CONTACT_US: {
		route: Route,
		path: ROUTE_PATHS.CONTACT_US,
		component: ContactUs
	},
	NOT_FOUND: {
		route: Route,
		path: ROUTE_PATHS.NOT_FOUND,
		component: NotFound
	}
};

export const routesArr = Object.values(ROUTES);
