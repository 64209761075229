import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { Styled } from 'styles/styled/components/Buttons';
import { MdClose as MdCloseIcon } from 'react-icons/md';

export const StickySection = styled.div`
	position: fixed;
	z-index: 10;
	bottom: 10px;
	max-width: 350px;
	width: 100%;
	border-radius: 20px;
	box-shadow: 0 10px 50px 0 rgba(0, 0, 0, 0.1);
	background-color: #fff;
	padding: 33.9px 40px 33.7px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	&:before {
		background-image: url('/images/rebranding/GreenSplashCard.png');
		background-repeat: no-repeat;
		background-position: top right;
		position: absolute;
		content: '';
		background-size: contain;
		width: 250px;
		height: 250px;
		top: 0;
		right: 0;
		z-index: -1;
	}

	@media (max-width: 420px) {
		position: sticky;
		margin: 0 auto;
	}

	@media (min-width: 420px) {
		right: 10px;
	}
`;

export const LinkButton = styled(Link)`
	font-size: 18px;
	font-family: overlock-bold;
	margin-top: 2rem;
	color: #000;
`;

export const Text = styled.div`
	font-size: 16px;
	margin-top: 1rem;
	margin-bottom: 1rem;
	text-align: center;
`;

export const Title = styled.div`
	font-size: 24px;
	font-family: overlock-bold;
`;

export const StyledButton = styled(Styled)`
	min-width: 150px;
`;

export const Container = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
`;

export const Image = styled.img`
	margin-top: 2rem;
	margin-bottom: 2rem;
	max-width: 100px;
`;

export const Block = styled.div`
	display: flex;
	flex-direction: column;
	align-items: flex-end;
	width: 100%;
`;

export const Close = styled(MdCloseIcon)`
	font-size: 30px;
	cursor: pointer;
`;
