import React from 'react';
import PropTypes from 'prop-types';

import { withQuizContext } from 'context/withQuizContext';
import { QuizFrame, Button, H1, Text, Container } from './Quiz.styled';

// build label to display depending on which sections are unfinished
const buildQuizLabel = (style, artistry, color) => {
	let sections = [];

	if (!style) sections.push('Style');
	if (!artistry) sections.push('Artistry');
	if (!color) sections.push('Color');

	if (sections.length === 0) return '';
	if (sections.length === 1) return sections[0];
	if (sections.length === 2) return `${sections[0]} and ${sections[1]}`;
	return `${sections[0]}, ${sections[1]} and ${sections[2]}`;
};

const QuizIntro = ({ quizContext }) => {
	const { nextScreen } = quizContext.actions;

	const { style, artistry, color } = quizContext.state.results;

	const hasStartedQuiz = quizContext.state.hasStartedQuiz;

	return (
		<Container>
			<QuizFrame>
				<Text>
					<H1>
						<span>
							{hasStartedQuiz ? 'Finish' : 'Take'} the Quiz to find out your{' '}
						</span>{' '}
						<br />
						{buildQuizLabel(style, artistry, color)}
					</H1>
					You will be presented several choices, choose instinctively.
				</Text>
				<div>
					<Button onClick={nextScreen}>Start</Button>
				</div>
			</QuizFrame>
		</Container>
	);
};

QuizIntro.propTypes = {
	quizContext: PropTypes.shape({
		actions: PropTypes.object.isRequired
	})
};

export default withQuizContext(QuizIntro);
