import API from 'utils/apiConfig';

const editGeneral = (id, email, firstName, lastName) => {
	const data = {
		email,
		first_name: firstName,
		last_name: lastName
	};

	return API.patch(`api/users/${id}/`, data);
};

const editProfile = (id, address, city, province, postalCode) => {
	const data = {
		address,
		city,
		province,
		postal_code: postalCode
	};

	return API.patch(`api/users/${id}/profile/`, data);
};

const updateProfileImage = async (userId, image, filename) => {
	const formData = new FormData();
	formData.append(`picture`, image, filename || 'cropped.jpg');
	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	};

	const response = await API.patch(
		`api/users/${userId}/picture/`,
		formData,
		config
	);
	return response.data;
};

const updatePassword = (currentPassord, newPassword) => {
	const data = {
		old_password: currentPassord,
		new_password: newPassword
	};

	return API.put(`api/users/edit_password/`, data);
};

const createProfile = (email, password, anonQuizUser = null) => {
	const data = {
		email,
		password
	};

	if (anonQuizUser) {
		data.anonQuizUser = anonQuizUser;
	}

	return API.post(`api/users/create/`, data);
};

const createResetToken = (email) => {
	const data = {
		email
	};

	return API.post(`api/users/create_reset_password_token/`, data);
};

const resetPassword = (userId, password, confirmPassword) => {
	const data = {
		user_id: userId,
		password,
		confirm_password: confirmPassword
	};

	return API.put(`api/users/reset_password/`, data);
};

const resetPasswordByToken = (token, password, confirmPassword) => {
	const data = {
		token,
		password,
		confirm_password: confirmPassword
	};

	return API.put(`api/users/reset_password_token/`, data);
};

const fetchUser = async (userId) => {
	const response = await API.get(`/api/users/${userId}/`);
	return await response.data;
};

const deleteUser = async (userId) => {
	try {
		const response = await API.delete(`/api/users/${userId}/`);
		if (response.status === 204) return true;
		return null;
	} catch (e) {
		return null;
	}
};

const acceptAnonymousCookies = async (userName) => {
	const data = {
		has_accepted_policy: true
	};
	const response = await API.patch(
		`api/users/${userName}/change_cookie_policy_status/`,
		data
	);
	return await response.data;
};

const acceptAnonymousPrivacyPolicy = async (userName) => {
	const data = {
		has_accepted_policy: true
	};
	const response = await API.patch(
		`api/users/${userName}/change_privacy_policy_status/`,
		data
	);
	return await response.data;
};

const acceptCookies = async () => {
	const data = {
		has_accepted_policy: true
	};
	const response = await API.patch(
		`api/users/change_cookie_policy_status/`,
		data
	);
	return await response.data;
};

const acceptPrivacyPolicy = async () => {
	const data = {
		has_accepted_policy: true
	};
	const response = await API.patch(
		`api/users/change_privacy_policy_status/`,
		data
	);
	return await response.data;
};

const subscribeUserToMailchimp = () => {
	return API.post(`api/users/mailchimp/subscribe/`, {});
};

const unsubscribeUserFromMailchimp = (body) => {
	return API.patch(`api/users/mailchimp/unsubscribe/`, body);
};

const subscribeGuestUserToMailchimp = ({ email, first_name, last_name }) => {
	const data = {
		email,
		first_name: first_name,
		last_name: last_name
	};
	return API.post(`api/users/mailchimp/guests/subscribe/`, data);
};

export default {
	createProfile,
	createResetToken,
	updateProfileImage,
	deleteUser,
	editGeneral,
	editProfile,
	fetchUser,
	resetPassword,
	resetPasswordByToken,
	updatePassword,
	acceptCookies,
	acceptPrivacyPolicy,
	acceptAnonymousCookies,
	acceptAnonymousPrivacyPolicy,
	subscribeUserToMailchimp,
	unsubscribeUserFromMailchimp,
	subscribeGuestUserToMailchimp
};
