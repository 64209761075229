import React, { useContext } from 'react';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';

import useModal from 'components/New/Modal/useModal';
import { A } from 'styles/styled/components';
import { RootContext } from "context/RootContext";

export const RetakeLink = styled(A).attrs({
  as: 'span'
})`
  cursor: pointer;
  font-family: ${props => props.theme.fonts.black};
`;

const RetakeQuiz = ({ children }) => {
  const { Modal, showModal } = useModal();
  const { actions } = useContext(RootContext);
  const history = useHistory();

  const handleRetake = async() => {
    await actions.retakeQuiz();
    history.replace('/quiz');
  };

  return (
    <>
      {(children &&
        React.Children.map(children, child => {
          if (React.isValidElement(child))
            return React.cloneElement(child, { onClick: showModal });
          return <span onClick={showModal}>{child}</span>;
        })) || (
        <RetakeLink onClick={showModal}>
          Retake the quiz
        </RetakeLink>
      )}
      <Modal
        title="Are you sure you want to continue"
        rejectText="No"
        confirmText="Yes"
        onConfirm={handleRetake}
      >
        Completing the quiz again will overwrite your current results.
      </Modal>
    </>
  );
};

export default RetakeQuiz;
