import React from 'react';
import PropTypes from 'prop-types';

import ScrollToTopOnMount from '../ScrollToTopOnMount/ScrollToTopOnMount';
import Provider from 'context/QuizContext';
import { withRootContext } from 'context/withRootContext';
import StepContent from './StepContent';
import { BackgroundGraphics } from './Quiz.styled';
import OgHelmet from 'components/OgHelmet/OgHelmet';

const Quiz = (props) => {
	const { user } = props.context.state;
	const { setQuizProgress } = props.context.actions;

	return (
		<>
			<OgHelmet title={''} description="Your quiz" />
			<BackgroundGraphics>
				<ScrollToTopOnMount />
				<Provider user={user} setQuizProgress={setQuizProgress}>
					<StepContent />
				</Provider>
			</BackgroundGraphics>
		</>
	);
};

Quiz.propTypes = {
	context: PropTypes.shape({
		state: PropTypes.object,
		actions: PropTypes.object
	})
};

export default withRootContext(Quiz);
