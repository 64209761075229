import styled from 'styled-components';

export const Wrapper = styled.div`
  display: flex;
  position: relative;
  min-width: 20px;
  overflow: hidden;
  white-space: nowrap;
  ${props => props.theme.noSelect}
`;

export const Content = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  left: 22%;
  top: 22%;
  bottom: 22%;
  right: 22%;
  >*{
    max-width: 100%;
  }
`;

export const Svg = styled.svg`
  position:absolute;
  left:0;
  right:0;
  top:0;
  bottom:0;
`;

export const Placeholder = styled.img`
  min-width: 100%;
  margin-left: -100%;
  vertical-align: bottom;
`;

export const SvgPath = styled.path`
  stroke-width: 4px;
  fill: none;
  stroke: ${props => props.color}
`;

export const ActiveCircle = styled(SvgPath).attrs({
  as: 'circle'
})`
  opacity: ${props => props.visible ? 1 : 0};
  ${Wrapper}:hover & {
    opacity: 1;
  }
`;


