export const TYPES = [
	{
		title: 'Style',
		key: 'style'
	},
	{
		title: 'Artistry',
		key: 'artistry'
	},
	{
		title: 'Color',
		key: 'color'
	}
];

export const getAttributeFieldName = (fieldType) => {
	switch (fieldType) {
		case 'style':
			return 'style_type';
		case 'artistry':
			return 'artistry_type';
		case 'color':
			return 'color_type';

		default:
			throw new Error('Unknown field type!');
	}
};

export const onImageLoaded = (urls = [], timeout = 5000) => {
	return Promise.all(
		urls.map((url) => {
			return new Promise((resolve, reject) => {
				const timer = setTimeout(reject, timeout);
				const img = new Image();
				img.addEventListener('load', () => {
					clearTimeout(timer);
					resolve();
				});
				img.addEventListener('error', () => {
					clearTimeout(timer);
					reject();
				});
				img.src = url;
			});
		})
	);
};

export const DEFAULT_COLOR_SWATCH_HEX = '#FFFFFF';

export const CONTENT_TYPE = {
	IMAGE: 'image_field',
	PLAIN_TEXT: 'plain_text',
	RICH_TEXT: 'rich_text',
	COLOR_SWATCH: 'color_swatch'
};
