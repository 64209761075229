import API from 'utils/apiConfig';

const getQuizProgress = async () => {
	const response = await API.get(`api/quiz/`);
	return await response.data;
};

const getQuizProgressForUser = async (userId) => {
	const response = await API.get(`api/quiz/user/${userId}/`);
	return await response.data;
};

const getQuizProgressByUsername = async (username) => {
	const response = await API.get(`api/quiz/username/${username}/`);
	return await response.data;
};

const fetchQuestions = async (section, param = null) => {
	if (!section) {
		return Promise.reject('Section parameter is required');
	}

	let url = `api/quiz/questions/${section}/`;

	if (param) {
		url += `?param=${param}`;
	}

	const response = await API.get(url);
	return await response.data;
};

const handleStyleSectionQuestions = async (selectedChoices, username) => {
	const response = await API.put('api/quiz/style-section/', {
		selected_choices: selectedChoices,
		username: username
	});

	return response.data;
};

const sendResponses = async (section, responses, username) => {
	if (!section) {
		return Promise.reject('Section parameter is required');
	}

	const data = { section, responses, username };

	const response = await API.put(`/api/quiz/respond/`, data);
	return await response.data;
};

const getAttributeTypes = async () => {
	const response = await API.get(`/api/quiz/types/`);
	return await response.data;
};

const editAttribute = async (type, id, name, text, medias, image, color) => {
	if (!type) {
		return Promise.reject('Attribute type is required');
	}

	let videos = [];
	let pictures = [];

	if (medias?.length > 0) {
		medias.forEach((media) => {
			if (media.is_image) {
				pictures.push(media);
			} else {
				videos.push(media);
			}
		});
	}

	const formData = new FormData();

	formData.append(`type`, type);
	formData.append(`id`, id);
	formData.append(`name`, name);
	formData.append(`text`, text);
	if (image) {
		formData.append(`icon`, image);
	}
	if (color) {
		formData.append(`color_hex`, color);
	}

	pictures.forEach((pic, index) => {
		formData.append(`url_${index}`, pic.id ? pic.url : pic.file);
		formData.append(`order_number_${index}`, pic.order_number);
		formData.append(`image_id_${index}`, pic.id || 0);
		formData.append(`desc_${index}`, pic.desc);
	});

	if (videos.length > 0) {
		formData.append(`videos`, JSON.stringify(videos));
	}

	const config = {
		headers: {
			'content-type': 'multipart/form-data'
		}
	};

	const response = await API.put(`/api/quiz/types/edit/`, formData, config);
	return await response.data;
};

const getArtistryTypes = async () => {
	const response = await API.get(`api/quiz/artistrytype/`);
	return await response.data;
};

const getColorGroups = async () => {
	const response = await API.get(`api/quiz/colorgroups/`);
	return await response.data;
};

const createAnonUser = async (data) => {
	const response = await API.post(`api/quiz/user/`, data);
	return await response.data;
};

const deleteInactiveAccounts = async () => {
	const response = await API.delete(`api/quiz/clear_inactive_users/`);
	return await response.data;
};

const updateUserQuizProgress = async (userId, data) => {
	const response = await API.patch(`api/quiz/user/${userId}/type/`, data);

	return await response.data;
};

const deleteResultsById = async (userId) => {
	const response = await API.delete(`api/quiz/user/${userId}/`);

	return await response.data;
};

const deleteResultsByUsername = async (username) => {
	const response = await API.delete(`api/quiz/username/${username}/`);

	return await response.data;
};

const resetQuizResults = async (username) => {
	const response = await API.post(`api/quiz/results/${username}/reset/`, {});

	return await response.data;
};

const changeQuizResultsLoggedUser = async (data) => {
	const response = await API.patch(`api/quiz/results/`, data);

	return response.data;
};

const changeQuizResultsAnonUser = async (username, data) => {
	const response = await API.patch(`api/quiz/results/${username}/`, data);

	return response.data;
};

const sendMessage = async (payload) => {
	const response = await API.post(`api/quiz/contact-us/`, payload);

	return response.data;
};

export default {
	createAnonUser,
	deleteInactiveAccounts,
	deleteResultsById,
	deleteResultsByUsername,
	editAttribute,
	getAttributeTypes,
	getQuizProgress,
	getQuizProgressForUser,
	getQuizProgressByUsername,
	fetchQuestions,
	sendResponses,
	getArtistryTypes,
	getColorGroups,
	updateUserQuizProgress,
	changeQuizResultsLoggedUser,
	changeQuizResultsAnonUser,
	handleStyleSectionQuestions,
	resetQuizResults,
	sendMessage
};
