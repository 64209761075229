import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Grid } from 'react-bootstrap';

import Spinner from 'components/Spinner/Spinner';

import { withQuizContext } from 'context/withQuizContext';

const SpinnerContainer = () => {
	return <Spinner />;
};

class StepContent extends Component {
	render() {
		const { step, isLoading } = this.props.quizContext.state;

		const Content = step.component;
		const params = step.params;

		return isLoading ? (
			<SpinnerContainer />
		) : (
			<Grid>
				<Content params={params} />
			</Grid>
		);
	}
}

StepContent.propTypes = {
	quizContext: PropTypes.shape({
		state: PropTypes.object.isRequired,
		actions: PropTypes.object.isRequired
	})
};

export default withQuizContext(StepContent);
