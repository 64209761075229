import React from 'react';
import Navigation from '../Navigation/Navigation';

const Header = () => {
	return (	
    <header>
      <Navigation />
    </header>
	);
};

export default Header;
