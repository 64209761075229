export const ROUTE_PATHS = {
	HOME: '/',
	COOKIE_POLICY: '/cookie-policy',
	PRIVACY_POLICY: '/privacy-policy',
	ABOUT: '/about',
	SHOP: '/shop',
	DISCOVER: '/discover',
	DISCOVER_ARTICLE: '/discover/:slug?',
	SEARCH: '/search',
	LOGIN: '/login/:mode?',
	REGISTER: '/register',
	FORGOT_PASSWORD: '/forgot',
	RESET_PASSWORD: '/reset/:token',
	AUTH: '/oauth/:tokens',
	QUIZ: '/quiz/:query?',
	PERSONA: '/persona',
	LEARN: '/learn/:type?/:slug?',
	PROFILE: '/settings',
	DASHBOARD: '/dashboard',
	CONTACT_US: '/contact-us',
	NOT_FOUND: '*'
};
